import React from 'react';
import { Button, Typography } from '@mui/material';
import '../../styles/styles.css';
import Heading2 from '../../styles/components/Heading2';
import Heading6 from '../../styles/components/Heading6';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import { Link } from 'react-router-dom';
import { BaseUrl } from '../../stateManagers/apiConfig';

interface BannerBlockProps {
  fullData: PageItem; // Declare fullData as PageItem type
}

// types.ts (or any suitable file)
export interface PageItem {
  heading: string;
  subheading: string;
  smallheading: string;
  buttonValues: any[]; // Adjust the type of buttonValues if necessary
  layout: string;
  img: any;
}


function BannerBlock( { fullData }: BannerBlockProps) {
  const img = fullData.img;
  const heading = fullData.heading;
  const subheading = fullData.subheading;
  const btnValue = fullData.buttonValues;
  const imgSrc = BaseUrl + img; // Corrected to use img from fullData
  const backgroundStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0.32) 95%), url(${imgSrc}), center`, backgroundSize:"cover"
  };
    return(
<div>
<section className='section-back landing-section' style={backgroundStyle} >
      <div style={{ position: 'relative', zIndex: 1, textAlign: 'center', color: 'white' }}>
        <Heading6 text={heading} />
        <DynamicSpace gs={16} sm={12} md={15} />
        <Heading2 text={subheading} className="heading-mobile" />
        <div className="button-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
        {btnValue && btnValue.map((btn, index) => (
            <a href={btn.btnlink} key={index} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary" className={btn.btnclass}>
            <Typography className='landingButtonsTypography' variant="body1">
            {btn.bname}
              </Typography>
            </Button>
            </a>
          ))}
        </div>
      </div>
    </section>
</div>

)

};
export default BannerBlock;