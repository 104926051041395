import React from 'react';
import '../../styles/styles.css'; // Import global CSS file
import GlobalHeading from '../../styles/components/GlobalHeading';
import GlobalParagraph from '../../styles/components/GlobalParagraph';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import GlobalAnchor from '../../styles/components/GlobalAnchor';
import { Link } from 'react-router-dom';
import { BaseUrl } from '../../stateManagers/apiConfig';
interface CenterBlockProps {
  fullData: PageItem; // Declare fullData as PageItem type
}
// types.ts (or any suitable file)
export interface PageItem {
  heading: string;
  subheading: string;
  smallheading: string;
  buttonValues: any[]; // Adjust the type of buttonValues if necessary
  layout: string;
  img: any;
}


const CenterBlock: React.FC<CenterBlockProps> = ({ fullData }) => {
  // Accessing img from fullData
  const img = fullData.img;
  const heading = fullData.heading;
  const subheading = fullData.subheading;
  const btnValue = fullData.buttonValues;
  const imgSrc = BaseUrl + img; // Corrected to use img from fullData
  const backgroundStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0.32) 95%), url(${imgSrc}), center`, backgroundSize:"cover"
  };

  return (
     <div>
       <div>
       <section className='section-back content-section' style={backgroundStyle}>
        <div className='content-card' >
          <GlobalHeading text={heading} variant='h2' fontSize={{ xs: '12px', md: '12px' }} />
          <DynamicSpace gs={14} />
          <GlobalParagraph
            text={subheading} // Assuming subtitle is what you want to display, not key
            fontSize={{ xs: '12px', md: '14px' }}
            lineHeight='1.6'
            letterSpacing='0.05em'
            color='#555'
          />
          <DynamicSpace gs={14} />
          {btnValue &&
            btnValue.map((btn, index) => (
              <a key={index} style={{ fontSize: '13px', color: 'black!important' }} className='link2' href={btn.btnlink}>
                {btn.bname}
              </a>
            ))}
        </div>
      </section>
       </div>
     </div>
  );
};

export default CenterBlock;
