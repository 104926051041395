import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface SelectedSlot {
  date: any; // Use 'any' type temporarily
  slot: number;
}

interface CalendarState {
  selectedSlots: SelectedSlot[];
}

const initialState: CalendarState = {
  selectedSlots: [],
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setSelectedSlots: (state, action: PayloadAction<SelectedSlot[]>) => {
      state.selectedSlots = action.payload;
    },
    updateSelectedSlot: (state, action: PayloadAction<SelectedSlot>) => {
      const updatedSlot = action.payload;
      const existingSlotIndex = state.selectedSlots.findIndex(
        slot => slot.date === updatedSlot.date && slot.slot === updatedSlot.slot
      );
      if (existingSlotIndex !== -1) {
        // Slot already exists, remove it to deselect
        state.selectedSlots = state.selectedSlots.filter((_, index) => index !== existingSlotIndex);
      } else {
        // Slot doesn't exist, add it to select
        state.selectedSlots.push(updatedSlot);
      }
    },
  },
});

export const { setSelectedSlots, updateSelectedSlot } = calendarSlice.actions;

export const selectSelectedSlots = (state: RootState) => state.calendar.selectedSlots;

export default calendarSlice.reducer;
