import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import Profile from './components/profile';
import { Box, Grid, AppBar, Toolbar, Typography } from '@mui/material';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import { AccountCircle, BookOnline, LibraryBooks, Logout } from '@mui/icons-material';
import MyBookings from './components/MyBookings';
import GlobalAnchor from '../../styles/components/GlobalAnchor';
import useAuth from '../../stateManagers/useAuth';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import LogOut from './components/Logout';
import MakeBooking from './components/MakeBooking';

// Define types for menu items
type MenuItem = {
  id: number;
  title: string;
  icon: React.ReactNode; // Define icon type
  component: React.ComponentType<any>;
};

const menuItems: MenuItem[] = [
  { id: 1, title: 'Dashboard', icon: <AccountCircle />, component: Profile },
  { id: 2, title: 'My Bookings', icon: <LibraryBooks />, component: MyBookings },
  { id: 3, title: 'Make Booking', icon: <BookOnline />, component: MakeBooking },
  { id: 4, title: 'Logout', icon: <Logout />, component: LogOut },
  // Add more menu items as needed
];

const UserDashboard: React.FC = () => {
  const authHook = useAuth();
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem | null>(null);
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isToastShown, setIsToastShown] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const delayedCheckAuth = async () => {
      // Introduce a delay before checking authentication
      await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust the delay duration as needed

      const loggedIn = await authHook.verifyToken();
      setIsLoggedIn(loggedIn);

      if (!loggedIn && !isToastShown) {
        navigate('/login');
      }
    };

    delayedCheckAuth();

    return () => {
      // Any cleanup logic you need (if applicable)
    };
  }, [navigate, authHook.verifyToken, isToastShown]);

  useEffect(() => {
    // Set the Profile component as the default selected menu item
    setSelectedMenuItem(menuItems[0]);
  }, []); // Run this effect only once, when the component mounts

  const handleMenuItemClick = (menuItem: MenuItem) => {
    setSelectedMenuItem(menuItem);
    if (isMobile) {
      setIsDrawerOpen(false); // Close the drawer on mobile after selecting an item
    }
    if (menuItem.title === 'Logout') {
      handleLogout(); // Call the handleLogout function
    }
    if (menuItem.title === 'Make Booking') {
      MakeBooking(); // Call the handleLogout function
    }
  };

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const MakeBooking = () => {
    navigate('/make-an-appointment');
  };

  const handleLogout = async () => {
    const action = authHook.logout(); // Call the logout function
    dispatch(action); // Dispatch the action
    setIsLoggedIn(false); // Update isLoggedIn state

    // Show the toast notification and wait for its onClose callback
    setIsToastShown(true); // Set the flag to true before showing the toast
    await new Promise((resolve) => {
      toast.success('Logged Out', {
        position: 'top-right',
        autoClose: 800,
        onClose: resolve,
      });
    });
    setIsToastShown(false); // Set the flag to false after the toast is closed
  };

  if (isLoggedIn === null) {
    // Loading state, you can show a loader here
    return null;
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
          <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            anchor="left"
            open={isMobile ? isDrawerOpen : true}
            onClose={() => setIsDrawerOpen(false)}
            sx={{
              width: isMobile ? '100%' : 'auto',
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: isMobile ? '100%' : 245,
                background: isMobile ? '#76a1b1' : '#76a1b1',
                boxSizing: 'border-box',
              },
            }}
          >
            {isMobile && (
              <div style={{ textAlign: 'right', padding: '8px' }}>
                <IconButton onClick={handleCloseDrawer}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}

            <DynamicSpace gs={30} xs={0} />

            <List
              component="nav"
              sx={{
                width: isMobile ? '100%' : 245, // Use dynamic width for mobile screens
                boxSizing: 'border-box',
              }}
            >
              <DynamicSpace gs={25} xs={10} md={25} lg={25} xl={25} />
              {menuItems.map((item) => (
                <ListItem sx={{ paddingBottom: '20px', paddingTop: '18px' }} key={item.id} button onClick={() => handleMenuItemClick(item)}>
                  <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                  <ListItemText sx={{ color: 'white' }} primary={item.title} />
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Grid>

        <Grid item xs={12} sm={8} md={9} lg={10} xl={10}>
          <AppBar sx={{ background: 'white' }} position="static">
            <Toolbar>
              {isMobile && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleToggleDrawer}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon sx={{ color: 'black', ml: 1 }} />
                </IconButton>
              )}
                <GlobalAnchor
                  text="MASTER'S HAIR & BEAUTY"
                  variant="h6"
                  fontSize={{ xs: '12px', md: '16px' }}
                  letterSpacing="0.2em"
                  lineHeight={{ xs: '1.5', md: '1.5' }}
                  color="black"
                  href="/"
                  underline={false}
                />
            </Toolbar>
          </AppBar>
          <DynamicSpace gs={40} />

          <Box sx={{ display: 'flex', placeContent: 'center' }}>
            <Box
              sx={{
                background: '#cadbe1',
                alignItems: 'center',
                padding: '16px',
                paddingLeft: '25px',
                paddingRight: '25px',
                borderRadius: '10px',
                width: '100%',
                maxWidth: '90%',
                margin: '0 auto', // Center the box horizontally
              }}
              style={{ boxShadow: 'rgb(0 0 0 / 9%) -1px 7px 15px 0px' }}
            >
              <DynamicSpace gs={20} />
              {selectedMenuItem ? <selectedMenuItem.component /> : null}

              <DynamicSpace gs={20} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
};

export default UserDashboard;
