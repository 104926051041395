import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStaff,
  deselectStaff,
} from "../../../stateManagers/staffMembersSlice";
import { RootState } from "../../../stateManagers/rootReducer";
import { showToast } from "../../../styles/components/toasty";
import "../../../styles/styles.css";

interface ActiveStaffProps {
  staffMembers: string[];
}

const ActiveStaff: React.FC<ActiveStaffProps> = ({ staffMembers }) => {
  const dispatch = useDispatch();
  const selectedStaff = useSelector(
    (state: RootState) => state.staffMembers.selectedStaff
  );

  const handleStaffSelection = (staff: string) => {
    if (selectedStaff === staff) {
      dispatch(deselectStaff());
    } else {
      dispatch(selectStaff(staff));
    }
  };

  const logSelectedStaff = () => {
    console.log("Selected staff member:", selectedStaff);
    showToast("Success notification", {
      type: "success",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <List sx={{ width: "100%" }} dense className="accordion_staff_wrapper">
        {staffMembers.map((staff) => (
          <div key={staff} style={{ width: "100%", boxSizing: "border-box" }}>
            <ListItem
              className="accordion_staff_wrapper"
              dense
              button
              onClick={() => handleStaffSelection(staff)}
              sx={{ width: "100%" }}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  sx={{
                    color: "#76a1b1", // Change the color here
                    "&.Mui-checked": {
                      color: "#76a1b1", // Change the color when checked
                    },
                  }}
                  checked={selectedStaff === staff}
                />
              </ListItemIcon>
              <Typography
                className="accordion_font_family staff_heading"
                variant="body1"
                sx={{ flexGrow: 1 }}
              >
                {staff}
              </Typography>
            </ListItem>
          </div>
        ))}
      </List>
      {/* <Button variant="contained" onClick={logSelectedStaff}>
        Log Selected Staff
      </Button> */}
    </>
  );
};

export default ActiveStaff;
