import React, { useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import GlobalHeading from "../styles/components/GlobalHeading";
import DynamicSpace from "../styles/Helpers/DynamicSpace";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "../stateManagers/apiConfig";

interface ContactFormProps {
  fullData: any; // Declare fullData as PageItem type
}

const ContactForm = ({ fullData }: ContactFormProps) => {
  const heading = fullData.heading;
  const subheading = fullData.subheading;
  const setcolumns  = fullData.setcolumns;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailSending, setIsEmailSending] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsEmailSending(true); // Show the Backdrop
      await axios.post(`${baseURL}/contactEmail`, { name, email, phone, message });
      toast.success("Message sent successfully");

      // Reset form fields if needed
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      console.error("Error submitting contact form:", error);
      toast.error("Error submitting the contact form.");
    } finally {
      setIsEmailSending(false); // Hide the Backdrop
    }
  };

  return (
    <>
    <div className="">
      
    <Backdrop open={isEmailSending} style={{ zIndex: 1000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <GlobalHeading className="typography-center" text={heading} />
      <GlobalHeading className="typography-center" text={subheading} />
      <DynamicSpace gs={30} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Your Name"
              fullWidth
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Your Email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Your Telephone Number (optional)"
              fullWidth
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Your Message"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#468bbf",
                justifyContent: "center",
                position: "relative",
                padding: "8px 0px",
              }}
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
      </form>
      <DynamicSpace gs={90} xs={10} sm={10} />
    </div>
    </>
  );
};

export default ContactForm;