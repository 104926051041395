import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios, { AxiosError } from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import DynamicSpace from "../../styles/Helpers/DynamicSpace";
import Cookies from "universal-cookie";
import { toast, ToastContainer } from "react-toastify";
import useAuth from "../../stateManagers/useAuth";
import { baseURL } from "../../stateManagers/apiConfig";
import { CircularProgress } from "@mui/material";
import Heading2 from "../../styles/components/Heading2";
import GlobalHeading from "../../styles/components/GlobalHeading";

const defaultTheme = createTheme();

const Register: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const authHook = useAuth();
  const navigate = useNavigate();
  const [isToastShown, setIsToastShown] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [cookieValue, setCookieValue] = useState<string | undefined>(undefined);
  // const [otp, setOtp] = useState('');

  const [otpSent, setOtpSent] = useState(false);

  useEffect(() => {
    const delayedCheckAuth = async () => {
      // Introduce a delay before checking authentication
      await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust the delay duration as needed

      const isLoggedIn = await authHook.verifyToken();
      if (isLoggedIn && !isToastShown) {
        navigate("/dash");
      }
    };

    delayedCheckAuth();

    // Cleanup function to prevent memory leaks
    return () => {
      // Any cleanup logic you need (if applicable)
    };
  }, [navigate, authHook.verifyToken, isToastShown]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!username) {
      toast.error("Username is required");
      return;
    } else if (!email) {
      toast.error("Email is required");
      return;
    } else if (!password) {
      toast.error("Password is required");
      return;
    }

    try {
      // Send OTP to the user's email
      setLoading(true);
      const otpResponse = await axios.post(`${baseURL}/registerviaotp`, {
        email,
        username,
        password,
      });

      if (otpResponse.data.message === "OTP sent") {
        setOtpSent(true);
        toast.success("OTP sent to your email", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.error(
          "An error occurred while sending OTP. Please try again later."
        );
      }

      const otpInput = document.getElementById("otp-input") as HTMLInputElement;
      if (otpInput) {
        otpInput.value = "";
      }

      setLoading(false);
    } catch (error: any) {
      console.log(error.response.data);

      if (error.response.data === "Username already exists") {
        toast.error("Username already exists", {
          position: "top-right",
          autoClose: 1500,
        });
      } else if (error.response.data === "Email already exists") {
        toast.error("Email already exists", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const otpValue = formData.get("otp") as string;

    if (!otpValue) {
      toast.error("Please enter the OTP");
      return;
    }

    try {
      setLoading(true);

      // Verify OTP and register the user
      const registerResponse = await axios.post(`${baseURL}/register`, {
        email,
        username,
        password,
        otp: otpValue,
      });

      if (registerResponse.data.message === "User registered successfully") {
        setIsToastShown(true); // Set the flag to true before showing the toast
        await new Promise((resolve) => {
          toast.success("Registration successfull. You can now login.", {
            position: "top-right",
            autoClose: 1500,
            onClose: resolve,
          });
        });
        setIsToastShown(false);
        // setOtp('');
        navigate("/login"); // Navigate to the login page after successful registration
      } else {
        toast.error(
          "An error occurred during registration. Please try again later."
        );
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false); // Always set loading to false after handling OTP submission
    }
  };

  const validateEmail = () => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    setEmailError(!isValidEmail);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const OTPInput = () => {
    return (
      <TextField
        margin="normal"
        required
        fullWidth
        id="otp-input"
        label="OTP"
        name="otp"
        autoComplete="off"
        sx={{
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#76a1b1",
            },
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#76a1b1",
          },
        }}
      />
    );
  };

  const validateUsername = () => {
    // Add validation logic for username, if needed
    setUsernameError(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#76a1b1" }}>
            <LockOutlinedIcon />
          </Avatar>
          <GlobalHeading text="Register" variant="h2" fontSize={{ xs: '20px', md: '22px' }} letterSpacing="0.07rem"  />
          {!otpSent ? (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                onChange={handleUsernameChange}
                onBlur={validateUsername}
                error={usernameError}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#76a1b1",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#76a1b1",
                  },
                }}
                helperText={usernameError ? "Username is required" : ""}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                error={emailError}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#76a1b1",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#76a1b1",
                  },
                }}
                helperText={emailError ? "Invalid email address" : ""}
                onChange={handleEmailChange}
                onBlur={validateEmail}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#76a1b1",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#76a1b1",
                  },
                }}
                autoComplete="current-password"
                onChange={handlePasswordChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#76a1b1",
                  ":hover": {
                    bgcolor: "#6590a0",
                    color: "white",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#76a1b1" }} />
                ) : (
                  "Register"
                )}
              </Button>
              <Grid container>
                <Grid item xs></Grid>
                <Grid item>
                  <Link
                    href="/login"
                    variant="body2"
                    style={{ color: "#76a1b1" }}
                  >
                    {"Already have an account? Log In"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={handleOtpSubmit}
              noValidate
              sx={{ mt: 1, textAlign:'center' }}
            >
              <DynamicSpace gs={7} />
              <GlobalHeading text="Please check your mail and enter otp below to complete registration." variant="h2" fontSize={{ xs: '12px', md: '14px' }} letterSpacing="0.07rem"  />
              <DynamicSpace gs={10} />



              <OTPInput key={`otp-input-${otpSent}`} />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#76a1b1",
                  ":hover": {
                    bgcolor: "#6590a0",
                    color: "white",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#76a1b1" }} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          )}
        </Box>
        <DynamicSpace gs={50} />
      </Container>
      <ToastContainer />
      <DynamicSpace gs={50} />
    </ThemeProvider>
  );
};

export default Register;
