// pageDataSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PageDataState {
  [x: string]: any;
  PageData: any | null;
}

const initialState: PageDataState = {
  PageData: null,
};

const fullPageDataSlice = createSlice({
  name: 'fullpageData',
  initialState,
  reducers: {
    setFullPageData(state, action: PayloadAction<any>) {
      state.PageData = action.payload;
    },
  },
});

export const { setFullPageData } = fullPageDataSlice.actions;

export default fullPageDataSlice.reducer;
