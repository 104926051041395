import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  userId: number | null;
  username: string | null;
  tokenExp: number | null;
}

const initialState: UserState = {
  userId: null,
  username: null,
  tokenExp: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserState>) => {
      state.userId = action.payload.userId;
      state.username = action.payload.username;
      state.tokenExp = action.payload.tokenExp;
    },
    clearUserData: (state) => {
      state.userId = null;
      state.username = null;
      state.tokenExp = null;
    },
  },
});

export const { setUserData, clearUserData } = userSlice.actions;
export default userSlice.reducer;