import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CenterBlock from './CenterBlock';
import BannerBlock from './BannerBlock';
import ImageWithText from './ImageWithText';
import BlogSection from './BlogBlock';
import { RootState } from '../../stateManagers/store';
import { setFullPageData } from '../../stateManagers/pageDataSlice';
import { Helmet } from 'react-helmet-async';
import ContentView from './ContentView';
import ContactForm from '../contactform';
import { useParams } from 'react-router-dom';
import FaqsContent from '../FAQ/faqsContent';
import { baseconUrL } from '../../stateManagers/apiConfig';
import { Container, CircularProgress, } from '@mui/material';
import ServicesData from '../Ourservices/servicesData';
import FaqSidebar from '../FAQ/faqSideBar';
import NotFoundPage from '../NotFoundPage';

interface PageItem {
  heading: string;
  subheading: string;
  smallheading: string;
  buttonValues: any[]; // Adjust the type of buttonValues if necessary
  layout: string;
  img: any;
  pageview: string;
  setcolumns:number;
}

const LandingSection: React.FC = () => {
const { slug } = useParams<{ slug?: string }>(); // Make slug optional
let slugval = slug ? slug : "home-page";
  const dispatch = useDispatch();
  // Type safety for useSelector
  const pageData = useSelector((state: RootState) => state.fullpagedata.PageData);
  const [loading, setLoading] = useState(true);
  const [Title, PageTitle] =  useState();
  const [pageView, PageView] = useState();
  
  const [pageSeoTitle, setPageSeoTitle] = useState<string>('');
  const [pageSeoDescription, setPageSeoDescription] = useState<string>('');
  const [pageSeoKeywords, setPageSeoKeywords] = useState<string>('');
  

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${baseconUrL}/api/pages/${slugval}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        // Parse the body property from JSON string to array of objects
        data.body = JSON.parse(data.body);
        dispatch(setFullPageData(data));
        PageView(data.pageview)

        PageTitle(data.title)
        setPageSeoTitle(data.seotitle);
        setPageSeoDescription(data.seodescription);
        setPageSeoKeywords(data.seokeywords);

        setLoading(false);

      } catch (error : any) {
        console.error('Error fetching data:', error.message);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) {
    return <div>
      <div className='text-center pt-5 pb-5' style={{ minHeight: "630px", display: "grid", justifyItems: "center", alignContent: "center",  background: "#ededed",}}>
      <CircularProgress />
      <p className='mt-3'>
        <b>Loading...</b> Please wait.</p>
      </div>
    </div>; // Render loading indicator
}
  if (error) return <div>{error}</div>;
  return (
    <>
      <Helmet>
      <title>{pageSeoTitle ? `${pageSeoTitle} | Master's Hair Beauty` : "Master's Hair Beauty"}</title>
        <meta
          name="description"
          content={pageSeoDescription ? `${pageSeoDescription} | Master's Hair Beauty` : "Master's Hair Beauty"}
        />
        <meta
          name="keywords"
          content={pageSeoKeywords ? `${pageSeoKeywords} | Master's Hair Beauty` : "Master's Hair Beauty"}
        />
      </Helmet>
      {/* Render components based on pageData */}
      {/* Conditional rendering based on pageView */}
      
      {pageView && pageView === "accordion" && (
        <>
        <div className="services_content" style={{ display: "flex", minHeight: "100vh" }}>
          {pageData && 
              pageData.body && 
              pageData.body.map((item: PageItem, index: number) => (
                <div key={String(index)}>
                {/* Conditional rendering based on layout */}
                {item.layout === "accordion" && (
                  <>
                    <FaqSidebar accordionItems={item} />
                  </>
                )}
              </div>
            ))}
          <div className="accordion_body" style={{ flex: 1, display: 'grid', marginLeft: '25px' }}>
          { pageData && 
            pageData.body && 
            pageData.body.map((item: PageItem, index: number) => (
              <div key={String(index)}>
              {/* Conditional rendering based on layout */}
              {item.layout === "banner" && (
                <BannerBlock fullData={item} />
              )}
              {item.layout === "center-block" && (
                <CenterBlock fullData={item} />
              )}
              {item.layout === "image-with-text-block" && (
                <ImageWithText fullData={item}  />
              )}
              {item.layout === "blogs" && (
                <BlogSection fullData={item} />
              )}
              {item.layout === "content-view" && (
                <ContentView fullData={item} />
              )}
              {item.layout === "contact-form" && (
                <ContactForm fullData={item} />
              )}
              {item.layout === "accordion" && (
                <>
                  <FaqsContent fullData={item} />
                </>
              )}
              {item.layout === "services" && (
                <ServicesData/>
              )}
            </div>
          ))}
        </div>
          </div>
        </>
        )}

       {pageView && 
        pageView === 'default' && 
        pageData && 
        pageData.body && 
        pageData.body.map((item: PageItem, index: number) => (
        <div key={String(index)}>
        {/* Conditional rendering based on layout */}
        
        {item.layout === "banner" && (
          <BannerBlock fullData={item} />
        )}
        {item.layout === "center-block" && (
          <CenterBlock fullData={item} />
        )}
        {item.layout === "image-with-text-block" && (
          <ImageWithText fullData={item}  />
        )}
        {item.layout === "blogs" && (
          <BlogSection fullData={item} />
        )}
        {item.layout === "content-view" && (
          <ContentView fullData={item} />
        )}
        {item.layout === "contact-form" && (
          <ContactForm fullData={item} />
        )}
        {item.layout === "accordion" && (
          <FaqsContent fullData={item}/>
        )}
        {item.layout === "services" && (
          <ServicesData/>
        )}
      </div>
      ))}
      {pageView && 
        pageView === 'contentview' && 
        pageData && 
        pageData.body && 
        pageData.body.map((item: PageItem, index: number) => (
        <div key={String(index)}>
        {/* Conditional rendering based on layout */}
          <Container>
            <div className='row justify-content-md-center'>
              <div className='col-lg-7'>
              <div className='content-view'>
                {item.layout === "banner" && (
                  <BannerBlock fullData={item} />
                )}
                {item.layout === "center-block" && (
                  <CenterBlock fullData={item} />
                )}
                {item.layout === "image-with-text-block" && (
                  <ImageWithText fullData={item}  />
                )}
                {item.layout === "blogs" && (
                  <BlogSection fullData={item} />
                )}
                {item.layout === "content-view" && (
                  <ContentView fullData={item} />
                )}
                {item.layout === "contact-form" && (
                  <ContactForm fullData={item} />
                )}
                {item.layout === "accordion" && (
                  <FaqsContent fullData={item}/>
                )}
                {item.layout === "services" && (
                  <ServicesData/>
                )}
              </div>
              </div>
            </div>
          </Container>
      </div>
      ))}

      {pageData ? (
        <></>
      ) : (
  // Render "page not found" when pageData.body is not available
        <><NotFoundPage/></>
      )}
      
  {/* Include BlogSection component */}
    </>
  );
};
export default LandingSection;