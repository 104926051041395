import React, { useState, useRef, MouseEvent } from "react";
import DOMPurify from 'dompurify';
import DynamicSpace from "../../styles/Helpers/DynamicSpace";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FaqsLanding from "./faqs_landing";

interface AccordionProps {
  fullData: {
    AccordionValue: AccordionItem[];
    heading: any;
    subheading: any;
  };
}

export interface AccordionItem {
  acc_title: string;
  content: AccordionItemSingle[];
}

export interface AccordionItemSingle{
  question: string;
  answer: string;
}

export const LeftSidebar: React.FC<{ accordionItems: AccordionItem[] }> = ({ accordionItems }) => {
  const handleQuestionClick = (index: number) => (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const section = document.getElementById(`section-${index}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="accordion_widget" style={{ position: "sticky", top: 0, width: "250px", height: "100vh" }}>
      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        {accordionItems.map((item, index) => (
          <li key={index}>
            <a
              href={`#section-${index}`}
              onClick={handleQuestionClick(index)}
              className="accordion_widget_title"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                display: "block",
                color: "black",
              }}
            >
              {item.acc_title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

  const FAQsAccordion: React.FC<AccordionProps> = ({ fullData }) => {
  const { AccordionValue, heading, subheading } = fullData;
  const [expandedQuestion, setExpandedQuestion] = useState<string | null>(null);
  const contentRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const handleQuestionChange = (question: string) => () => {
    setExpandedQuestion((prevExpandedQuestion) =>
      prevExpandedQuestion === question ? null : question
    );
  };

  return (
    <>
    
      <DynamicSpace gs={50} />
      <div className="services_content" style={{ 
        display: "flex", 
        // minHeight: "100vh" 
        }}>
        {/* <LeftSidebar accordionItems={AccordionValue} /> */}
        
        <div className="accordion_body" style={{ flex: 1, display: 'grid', marginLeft: '25px' }}>
        {/* <FaqsLanding /> */}
     
          <div style={{ position: "relative" }}>
          {heading && <h1>{heading}</h1> }
          {subheading && <p>{subheading}</p>}
              {AccordionValue.map((item, index) => (
                <div
                  key={index}
                  id={`section-${index}`}
                  ref={(el) => (contentRefs.current[item.acc_title] = el)}
                  style={{ marginBottom: "20px", listStyle: "none" }} >

                  <div className="accordion_title">{item.acc_title}</div>
                  {item.content.map((contentItem, contentIndex) => (
                    <div key={`${index}-${contentIndex}`}>
                      <div
                        onClick={handleQuestionChange(contentItem.question)}
                        style={{ cursor: "pointer" }}
                        className="accordion_sub_title"
                      >
                        {/* Prefixed icons to accordion subtitles */}
                        {expandedQuestion === contentItem.question ? <KeyboardArrowDownIcon sx={{ fontSize: '12px', marginRight:'10px' }} /> : <NavigateNextIcon sx={{ fontSize: '12px', marginRight:'10px' }} />}
                        {contentItem.question}
                      </div>
                      <div
                        className="accordion_sub_description_box"
                        style={{
                          maxHeight: expandedQuestion === contentItem.question ? `${contentRefs.current[item.acc_title]?.scrollHeight}px` : "0",
                          overflow: "hidden",
                          transition: "max-height 0.3s cubic-bezier(0.42, 0, 0.58, 1) 0s",
                        }}
                      >
                        {/* Sanitize the HTML content and set class */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              `<p class="accordion_sub-description">${contentItem.answer.replace(/\n/g, '<br>')}</p>`,
                              { ADD_TAGS: ['a'], ADD_ATTR: ['href'] }
                            )
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
        </div>
      </div>
    </>
  );
};

export default FAQsAccordion;
