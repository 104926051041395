import React, { useEffect, useState } from "react";
import { Typography, Button, Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { clearSelectedServices, selectSelectedServices } from "../../../stateManagers/servicesSlice";
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from "../../../stateManagers/rootReducer";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../../../styles/Helpers/LoadingOverlay";
import "../../../styles/styles.css";
import { baseURL } from "../../../stateManagers/apiConfig";

interface Service {
  id: number;
  price_ladies?: string;
  price_ladies_oap?: string;
  price_children?: string; // Added price_children property
}

interface ServiceDialogProps {
  showSelectDateButton: boolean;
  handleBookNow: () => void;
  selectedSlots?: SelectedSlot[];
}

interface SelectedSlot {
  date: Date;
  slot: number;
}

const ServiceDialog: React.FC<ServiceDialogProps> = ({
  showSelectDateButton,
  handleBookNow,
  selectedSlots,
}) => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const [isToastShown, setIsToastShown] = useState(false);
  const [isBookingInProgress, setIsBookingInProgress] = useState(false);
  const selectedServicesRedux = useSelector(selectSelectedServices);
  const { userId, username } = useSelector((state: RootState) => state.user);
  const selectedStaff = useSelector((state: RootState) => state.staffMembers.selectedStaff);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getTotalPrice = () => {
    let totalPrice = 0;
    selectedServicesRedux.forEach((service: Service) => {
      // Explicitly specify the type of `service`
      if (service.price_ladies) {
        totalPrice += parseFloat(service.price_ladies);
      }
      if (service.price_ladies_oap) {
        totalPrice += parseFloat(service.price_ladies_oap);
      }
      if (service.price_children) {
        totalPrice += parseFloat(service.price_children || "0");
      }
    });
    return totalPrice.toFixed(2);
  };





  const makebooking = async () => {
    setIsBookingInProgress(true);

    const numberOfSlotsData = selectedSlots ? selectedSlots.length : 0;
    
    if (!selectedSlots || selectedSlots.length === 0) {
      toast.warn("Please select a slot first.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsBookingInProgress(false);
    } else if (numberOfSlotsData !== selectedServicesRedux.length) {
      toast.error("Number of slots data does not match no of selected services.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsBookingInProgress(false);
    } else {
      // console.log("Selected Services:", selectedServicesRedux);
  
      // Construct the request body
      const currentDateTime = new Date();
  
      const selectedSlotsData = selectedSlots.map((slot, index) => {
        const slotDate = slot.date;
        const month = String(slotDate.getMonth() + 1).padStart(2, '0');
        const day = String(slotDate.getDate()).padStart(2, '0');
        const year = slotDate.getFullYear();
        const hours = slotDate.getHours();
        const minutes = String(slotDate.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12;
  
        const formattedSlotDateTime = `${month}/${day}/${year}, ${formattedHours}:${minutes}:00 ${ampm}`;
  
        return {
          slotDateTime: formattedSlotDateTime,
          serviceId: selectedServicesRedux[index].id,
        };
      });
  
      const requestBody = {
        userId: userId,
        selectedSlotsData,
        staff_member : selectedStaff
      };
      
  
      if(numberOfSlotsData == selectedServicesRedux.length){}



      try {
        // Make HTTP POST request to your API endpoint
        setIsBookingInProgress(true);

        
        const response = await axios.post(
          `${baseURL}/booking-new`,
          requestBody
        );
        // console.log('body', requestBody)
        // Handle response
        // console.log("Selected Slots:", selectedSlots);
        toast.success("Booking successful", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // console.log("Booking successful!", requestBody);
        // Add any additional logic after successful booking if needed
        setIsBookingInProgress(false);
        // Hide the dialog after successful booking
        setDialogOpen(false);
        setIsToastShown(true);

      // Wait until toast is closed before navigating
      await new Promise((resolve) => {
        setTimeout(resolve, 3000); // Adjust timeout to match toast autoClose time
      });
      navigate('/dash');
      } catch (error) {
        // console.error("Error booking service:", error);
        toast.error("Failed to book service. Please try again later.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setIsBookingInProgress(false);
      // console.log("happy2");
    }
  };

  

  useEffect(() => {
    // This effect runs when dialogOpen changes
    if (!dialogOpen) {
      // If dialog is closed, clear selected services
      dispatch(clearSelectedServices());
    }
  }, [dialogOpen, dispatch]);






  return (
    <><LoadingOverlay isLoading={isBookingInProgress} /><div
      className="dialog-container"
      style={{
        position: "fixed",
        bottom: 0,
        backgroundColor: "white",
        padding: 10,
        width: "40%",
      }}
    >
      <Box
      className="dialog-inner-container"
        style={{
          display: "flex",
          padding: "20px 30px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Box sx={{ mb: 1 }}>
            <div>
              <Typography className="accordion_price" variant="body1">
                {selectedServicesRedux.length}{" "}
                {selectedServicesRedux.length === 1 ? "Service" : "Services"} Selected
              </Typography>
            </div>
          </Box>
          <div>
            <Typography className="accordion_price" variant="body1">Total : £{getTotalPrice()}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {showSelectDateButton ? (
            <Button
              variant="contained"
              className="accordion_price"
              sx={{ bgcolor: "#76a1b1", width:"100%", "&:hover": { bgcolor: "#76a1b1" } }}
              color="primary"
              onClick={handleBookNow}
            >
              Select a Date
            </Button>
          ) : (
            <Button
              variant="contained"
              className="accordion_price"
              sx={{ bgcolor: "#76a1b1", "&:hover": { bgcolor: "#76a1b1" } }}
              color="primary"
              onClick={makebooking}
            >
              Book Now
            </Button>
          )}
        </Grid>
      </Box>
    </div></>
  );
};

export default ServiceDialog;
