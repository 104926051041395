import React from "react";
import SpecialOffers from "./SpecialOffers";
import AboutUs from "./AboutUs";
import DynamicSpace from "../../styles/Helpers/DynamicSpace";

const SpecialOffersAndAbout: React.FC = () => {
  return (
    <>
      <DynamicSpace gs={30} />
      <SpecialOffers />
      <DynamicSpace gs={30} />
      <AboutUs />
      <DynamicSpace gs={10} />
    </>
  );
};

export default SpecialOffersAndAbout;
