import React from 'react';
import GlobalHeading from '../styles/components/GlobalHeading';
import GlobalParagraph from '../styles/components/GlobalParagraph';
import { Container } from '@mui/material';
import DynamicSpace from '../styles/Helpers/DynamicSpace';
import sec2 from './images/landing.jpg';


const Aboutus: React.FC = () => {
  return (
    <section className='section-back'>
      <Container>
        <div className='content_panel'>
          <DynamicSpace gs={75} xs={50} sm={50}  />
          <GlobalHeading className='typography-center' text='About Us' />
          <DynamicSpace gs={30} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            className='typography-center'
            lineHeight='1.65'
            text='Welcome to Masters Hair & Beauty Salons, where convenience meets luxury.'
          />
          <DynamicSpace gs={40} />
          <img src={sec2} alt="Instantly Delivered" style={{ display: 'block', margin: '0 auto', maxWidth: '80%', maxHeight: '300px' }} />

          <DynamicSpace gs={40} />
          

          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text="Our commitment to excellence extends beyond convenience; it encompasses a comprehensive range of in-salon services and products designed to make you look and feel your very best. With our comprehensive Treatment Menus, Masters is here to cater to your every need. Explore our full range of services, from luxurious massages to expert hair color transformations, and book your appointment online with ease. Alternatively, you can view the full details, service menus, and pricing of each Masters location on our website."
          />
          <DynamicSpace gs={40} />


          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text="At Masters, your safety and comfort are our top priorities. We have implemented a number of Salon Policies to ensure a safe, friendly, professional, and convenient environment for all our guests. From stringent sanitation protocols to attentive staff trained to prioritize your well-being, we strive to exceed your expectations at every visit."
          />
          <DynamicSpace gs={40} />


          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text="Conveniently located in London, our Finsbury Park salon awaits to pamper you with our expert hairdressing services and premium haircare products. Whether you're seeking a fresh new look or simply stocking up on your favorite products, our comfortable salon space and knowledgeable experts are here to assist you every step of the way."
          />
          <DynamicSpace gs={95} xs={60} sm={60}  />

        </div>
      </Container>
    </section>
  );
};

export default Aboutus;
