import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import DynamicSpace from "../../styles/Helpers/DynamicSpace";
import Cookies from "universal-cookie";
import { toast, ToastContainer } from "react-toastify";
import useAuth from "../../stateManagers/useAuth";
import { jwtDecode } from "jwt-decode";
import { setUserData } from "../../stateManagers/userSlice";
import { useDispatch } from "react-redux";
import { baseURL } from "../../stateManagers/apiConfig";
import GlobalHeading from "../../styles/components/GlobalHeading";

const defaultTheme = createTheme();

const SignIn: React.FC = () => {
  const authHook = useAuth();
  const navigate = useNavigate();
  const [cookieValue, setCookieValue] = useState<string | undefined>(undefined);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true); // New state variable
  const dispatch = useDispatch();
  const [isToastShown, setIsToastShown] = useState(false);

  useEffect(() => {
    const delayedCheckAuth = async () => {
      // Introduce a delay before checking authentication
      await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust the delay duration as needed

      const isLoggedIn = await authHook.verifyToken();
      if (isLoggedIn && !isToastShown) {
        navigate("/dash");
      }
    };

    delayedCheckAuth();

    // Cleanup function to prevent memory leaks
    return () => {
      // Any cleanup logic you need (if applicable)
    };
  }, [navigate, authHook.verifyToken, isToastShown]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const emailOrUsername = formData.get("email") as string;
    // const username = formData.get('email') as string;
    const password = formData.get("password") as string;

    if (!emailOrUsername) {
      toast.error("Email or Username is required");
      return;
    } else if (!password) {
      toast.error("Password is required");
      return;
    }

    try {
      // Simulate user not found via API
      const response = await axios.post(`${baseURL}/login`, {
        email: emailOrUsername, // Send both email and username to the backend
        username: emailOrUsername,
        password: password,
      });

      // Save token to cookie
      const token = response.data.token;
      const cookies = new Cookies();
      const expirationDate = new Date(); // Current date/time
      expirationDate.setDate(expirationDate.getDate() + 7); // Expires in 7 days
      cookies.set("token", token, {
        path: "/",
        expires: expirationDate,
        sameSite: "lax",
      });
      setCookieValue(token); // Update state with cookie value
      setIsToastShown(true); // Set the flag to true before showing the toast
      await new Promise((resolve) => {
        toast.success("Logged in Successfully", {
          position: "top-right",
          autoClose: 1500,
          onClose: resolve,
        });
      });
      setIsToastShown(false); // Set the flag to false after the toast is closed

      const decodedToken: any = jwtDecode(token);
      // console.log('Decoded Token:', decodedToken);

      dispatch(
        setUserData({
          userId: decodedToken.userId,
          username: decodedToken.username,
          tokenExp: decodedToken.exp,
        })
      );

      // Check if the user is authenticated and navigate to the dashboard
      const isLoggedIn = await authHook.verifyToken();
      if (isLoggedIn && !isToastShown) {
        navigate("/dash");
      }

      console.log("logged in");
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "User not found"
      ) {
        toast.error("User not found");
      } else if (error.response.data.message === "Incorrect password") {
        toast.error("Incorrect password");
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      // console.error('Login failed:', error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#76a1b1" }}>
            <LockOutlinedIcon />
          </Avatar>
          <GlobalHeading text="Log in" variant="h2" fontSize={{ xs: '20px', md: '22px' }} letterSpacing="0.07rem"  />
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
           <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address or Username"
              name="email"
              autoComplete="email"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#76a1b1",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#76a1b1", // Change the color here
                },
              }}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#76a1b1",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#76a1b1", // Change the color here
                },
              }}
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#76a1b1",
                ":hover": {
                  bgcolor: "#6590a0", // theme.palette.primary.main
                  color: "white",
                },  
              }}
            >
              Log In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/reset-password" variant="body2" style={{ color: '#76a1b1' }}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/register"
                  variant="body2"
                  style={{ color: "#76a1b1" }}
                >
                  {"Don't have an account?  Register"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <DynamicSpace gs={50} />
      </Container>
      <ToastContainer />
      <DynamicSpace gs={50} />
    </ThemeProvider>
  );
};

export default SignIn;

