// OptionsValue.tsx
import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setSiteTitle, setInstagram, setWebsiteUrl, setFacebook, setTwitter } from './optionsSlice';
import { baseconUrL } from './apiConfig';

const OptionsValue: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseconUrL}/api/getoptions`, {
          option_name: ["website_url", "sitename", "instagram", "facebook", "twitter"],
        });
        // console.log('Response of options:', response.data);

        dispatch(setSiteTitle(response.data[0].option_value));
        dispatch(setWebsiteUrl(response.data[1].option_value));
        dispatch(setInstagram(response.data[2].option_value));
        dispatch(setFacebook(response.data[3].option_value));
        dispatch(setTwitter(response.data[4].option_value));

      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  return null;
};

export default OptionsValue;
