// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client'; // Import ReactDOM from client build
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';

// Create a root using ReactDOM.createRoot
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Render the App component inside the root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Measure performance using reportWebVitals
reportWebVitals();
