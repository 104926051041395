import { toast, ToastContent, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToastifyProps {
  position?: 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left';
  autoClose?: number | false;
  hideProgressBar?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  progress?: number;
  transition?: React.ComponentType<any>;
  className?: string;
}

interface SuccessErrorProps {
  successProps?: ToastifyProps;
  errorProps?: ToastifyProps;
}

const defaultToastifyProps: ToastifyProps = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const showToast = <TData = unknown>(
  content: ToastContent<TData>,
  options?: ToastOptions<TData> & ToastifyProps & SuccessErrorProps
) => {
  const { successProps, errorProps, ...toastOptions } = options || {};
  const mergedSuccessProps = { ...defaultToastifyProps, ...successProps };
  const mergedErrorProps = { ...defaultToastifyProps, ...errorProps };

  toast(content, {
    ...defaultToastifyProps,
    ...toastOptions,
    ...((toastOptions?.type === 'success' && mergedSuccessProps) || (toastOptions?.type === 'error' && mergedErrorProps)),
  });
};
