import { Grid, Typography } from '@mui/material';
import card00 from '../images/card00.jpg'; // Import the image file
import '../../styles/styles.css'; // Import global CSS file
import GlobalHeading from '../../styles/components/GlobalHeading';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import Heading2 from '../../styles/components/Heading2';
import GlobalParagraph from '../../styles/components/GlobalParagraph';
import GlobalAnchor from '../../styles/components/GlobalAnchor';
import { BaseUrl } from '../../stateManagers/apiConfig';

interface ImageWithTextProps {
  fullData: PageItem; // Declare fullData as PageItem type
}

// types.ts (or any suitable file)
export interface PageItem {
  heading: string;
  subheading: string;
  smallheading: string;
  buttonValues: any[]; // Adjust the type of buttonValues if necessary
  layout: string;
  img: any;
}
const ImageWithText = ({ fullData }: ImageWithTextProps) => {
  const img = fullData.img;
  const smallheading = fullData.smallheading
  const heading = fullData.heading;
  const subheading = fullData.subheading;
  const btnValue = fullData.buttonValues;
  const imgSrc = BaseUrl + img; // Corrected to use img from fullData
  const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0.32) 95%), url(${imgSrc})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  };

  return (
    <section className="section-back section-fifth" >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6}>
            <div style={{  maxWidth: '520px', padding: '48px 15px 48px 0px', textAlign:"left", color: "#000000"}}>
                <GlobalHeading text={smallheading} variant="h2" fontSize={{ xs: '12px', md: '12px' }} />
                <DynamicSpace gs={25} />
                <Heading2 text={heading} />
                <DynamicSpace gs={25} />
                <GlobalParagraph
                text={subheading}
                fontSize={{ xs: '13px', md: '14px' }}
                lineHeight="1.65"
                letterSpacing="0.005em"
                color="#454545"
                />
                <DynamicSpace gs={14} />
                <GlobalAnchor  text="Buy Online Now"  textAlign="left"  href="/gift-cards" />
            </div>
        </Grid>
        <Grid item xs={12} sm={6} className="fourthImage" style={{ 
          maxWidth: '100%', // Adjusted the max width
          maxHeight:'100%',
          height: '400px', // Set the height
          overflow: 'hidden', // Hide overflow content
        }}>
          <div style={backgroundStyle} />
        </Grid>
      </Grid>
    </section>
  );
};

export default ImageWithText;
