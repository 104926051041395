import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import DynamicSpace from '../../../styles/Helpers/DynamicSpace';
import { useSelector } from 'react-redux';
import { RootState } from '../../../stateManagers/rootReducer';
import { baseURL } from '../../../stateManagers/apiConfig';

interface Booking {
  user_username: string;
  booking_time: string;
  bookedslots: { [key: string]: BookedSlot[] };
}

interface BookedSlot {
  id: string;
  booking_id: string;
  staff_member: string;
  service_id: number;
  service_name: string;
  service_description: string;
  service_price: number;
  service_price_ladies: number;
  service_price_ladies_oap: number | null;
  service_price_children: number | null;
  slot_datetimes: string;
}

const MyBookings: React.FC = () => {
  const [bookingDetails, setBookingDetails] = useState<Booking[]>([]);
  const { userId, username } = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch booking details from the API
    fetch(`${baseURL}/userbookingDetails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: userId }), // Replace 123 with the actual user ID
    })
      .then(response => response.json())
      .then(data => {
        setBookingDetails(data.bookingDetails);
        setLoading(false); // Set loading to false after fetching data
      })
      .catch(error => {
        console.error('Error fetching booking details:', error);
        // Handle error
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  return (
    <div>
      
      {/* Render loader while loading */}
      {loading && (
        <div style={{ display: 'flex',justifyContent:'center',  alignItems: 'center', minHeight:'30vh' }}>
          <DynamicSpace gs={85} />
          <CircularProgress sx={{ color: "#549eb9" }} />
          <DynamicSpace gs={85} />
        </div>
      )}
      {/* Render booking details in table format */}
      {!loading && bookingDetails.length === 0 && (
        <div style={{ display: 'flex',justifyContent:'center',  alignItems: 'center' , textAlign: 'center', minHeight:'30vh' }}>
          <h2>No bookings found</h2>
        </div>
      )}
      {!loading && bookingDetails.length > 0 && bookingDetails.map((booking, index) => (
        <div key={index}>
          {/* Render booked slots in a table */}
          <h2>Booking's History</h2>
          <DynamicSpace gs={15} />
          <table>
            <thead>
              <tr>
                {/* <th>Booking ID</th> */}
                <th style={{ paddingRight: '30px' }}>Service's Selected</th>
                <th style={{ paddingRight: '30px' }}>Staff Member</th>
                <th style={{ paddingRight: '30px' }}>Slot Details</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(booking.bookedslots).map(([bookingId, slots]) => (
                <React.Fragment key={bookingId}>
                  {slots.map(slot => (
                    <React.Fragment key={slot.id}>
                      <tr>
                        {/* <td>{slot.booking_id}</td> */}
                        <td style={{ paddingRight: '30px' }}>{slot.service_name}</td>
                        <td style={{ paddingRight: '30px' }}>{slot.staff_member}</td>
                        <td style={{ paddingRight: '30px' }}>{slot.slot_datetimes}</td>
                      </tr>
                    </React.Fragment>
                  ))}
                  <DynamicSpace gs={10} />
                  <tr>
                    <td colSpan={4} style={{ borderTop: '0.5px solid #549eb9'}}>
                      <DynamicSpace gs={10} />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default MyBookings;
