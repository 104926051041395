import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalAnchor from '../styles/components/GlobalAnchor';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const theme = createTheme({
  typography: {
    fontFamily: ['Futura', 'sans-serif'].join(','),
  },
});

const BookingHeader: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 600px)');
    setIsMobile(mediaQuery.matches);

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          padding: '0px 24px',
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(121 127 132 / 34%)',
            ...(isMobile ? { paddingRight: '0px', paddingLeft:'7px' } : {}),
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between',
          ...(isMobile ? {} : { paddingRight: '0px' }),
         }}>
          <GlobalAnchor
            text="MASTER'S HAIR & BEAUTY"
            variant="h6"
            fontSize={{ xs: '12px', md: '16px' }}
            letterSpacing="0.2em"
            lineHeight={{ xs: '1.5', md: '1.5' }}
            color="black"
            href="/"
            textAlign="left"
            underline={false}
          />
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleClick}
              >
                <MenuIcon style={{ color: 'black' }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/about-us"
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      fontFamily: 'Futura, sans-serif',
                      fontSize: '13px',
                    }}
                  >
                    About Us
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/booking"
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      fontFamily: 'Futura, sans-serif',
                      fontSize: '13px',
                    }}
                  >
                    Book an Appointment
                  </Link>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <div>
              <a
                href="/about-us"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  marginRight: '16px',
                  fontFamily: 'Futura, sans-serif',
                  fontSize: '13px',
                }}
              >
                About Us
              </a>
              <a
                href="/booking"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  fontFamily: 'Futura, sans-serif',
                  fontSize: '13px',
                }}
              >
                Book an Appointment
              </a>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default BookingHeader;