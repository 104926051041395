import React from 'react';
import Typography from '@mui/material/Typography';

interface NavigationlinkProps {
  to: string;
  primaryText: string;
  disabled?: boolean; // Optional prop for disabled link
}

const Navigationlink: React.FC<NavigationlinkProps> = ({ to, primaryText, disabled = false }) => {
  return (
    <Typography
      component="div"
      onClick={() => {
        if (!disabled) window.location.href = to;
      }}
      style={{
        cursor: disabled ? 'default' : 'pointer', // Change cursor if disabled
        textDecoration: 'none', // Remove default underline
        fontFamily: 'Futura, sans-serif',
        color: '#8d8f8f',
        transition: 'all .2s ease-in-out',
        lineHeight: 1.5,
        fontSize: '13px',
        fontWeight: 200,
        pointerEvents: disabled ? 'none' : 'auto', // Disable pointer events if disabled
      }}
    >
      {primaryText}
    </Typography>
  );
};

export default Navigationlink;
