// useAuth.ts
import { useDispatch, useSelector } from 'react-redux';
import { setLoggedIn, logout } from './authSlice';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { RootState } from './rootReducer';
import { jwtDecode } from 'jwt-decode';
import { clearUserData, setUserData } from './userSlice';
import { baseURL } from './apiConfig';

interface AuthHook {
  verifyToken: () => Promise<boolean>;
  logout: () => { type: string };
}

const useAuth = (): AuthHook => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const cookies = new Cookies();

  const verifyToken = async () => {
    try {
      const token = cookies.get('token');
      if (!token) {
        dispatch(setLoggedIn(false));
        return false;
      }

      const response = await axios.post(`${baseURL}/verify-token`, { token });
      if (response.status === 200) {

        const decodedToken: any = jwtDecode(token);

        // Dispatch user data to Redux store
        dispatch(
          setUserData({
            userId: decodedToken.userId,
            username: decodedToken.username,
            tokenExp: decodedToken.exp,
          })
        );
        
        dispatch(setLoggedIn(true));
        return true;

      } else if (response.status === 401) { // Token expired
        // Remove token
        cookies.remove('token');
        dispatch(setLoggedIn(false));
        return false;

      } else {
        dispatch(setLoggedIn(false));
        cookies.remove('token');
        return false;
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      dispatch(setLoggedIn(false));
      cookies.remove('token');
      return false;
    }
  };

  const logoutHandler = (): { type: string } => {
    try {
      // Perform any cleanup actions like removing tokens or clearing local storage
      cookies.remove('token');
      dispatch(clearUserData());
      // Dispatch the logout action
      dispatch(logout());

      return { type: 'LOGOUT_SUCCESS' };
    } catch (error) {
    //   console.error('Error during logout:', error);
      return { type: 'LOGOUT_ERROR' };
    }
  };

  return { verifyToken, logout: logoutHandler };
};

export default useAuth;