import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import GlobalHeading from "../../styles/components/GlobalHeading";
import DynamicSpace from "../../styles/Helpers/DynamicSpace";
import DynamicDivider from "../../styles/Helpers/DynamicDivider";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ChatIcon from "@mui/icons-material/Chat";
import { Link } from "react-router-dom";

const AboutUs: React.FC = () => {
  return (
    <>
      <GlobalHeading
        text="About Us"
        variant="h3"
        fontSize={{ xs: "14px", md: "16px" }}
        fontWeight={600}
        letterSpacing="0.04em"
      />
      <DynamicSpace gs={10} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "10px",
        }}
      >
        {/* First Icon Section */}
        <DynamicSpace gs={5} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "10px",
            padding: "20px 10px",
          }}
        >
          {/* Icon */}
          <IconButton>
            <Link to={"https://www.google.com/maps/place/Master's+Hair+and+Beaty/@51.5619212,-0.1106783,15z/data=!4m6!3m5!1s0x48761ba1069aab13:0xba3cba2f82ab9a20!8m2!3d51.5619212!4d-0.1106783!16s%2Fg%2F11vwwy3jkv?hl=en&entry=ttu"}>
            <LocationOnIcon sx={{ color: "#76a1b1" }} />
            </Link>
          </IconButton>

          {/* Content */}
          <div>
            <GlobalHeading
              text="Find Us"
              color={"#76a1b1"}
              variant="h3"
              fontSize={{ xs: "11px", md: "13px" }}
              fontWeight={400}
              letterSpacing="0.04em"
            />
            <DynamicSpace gs={5} />
            {/* <GlobalHeading
              text="180 Seven Sisters Road, Finsbury Park, London N7 7PX"
              variant="h3"
              fontSize={{ xs: "12px", md: "14px" }}
              fontWeight={600}
              letterSpacing="0.04em"
            /> */}
            <p style={{margin:'0px', marginTop:'7px'}}>
              <a
                style={{
                  textDecoration: 'none',
                  fontSize: '14px',
                  letterSpacing: '0.04em',
                  fontWeight: '600',
                  color: 'black' // Change color to black
                }}
                href="https://www.google.com/maps/place/Master's+Hair+and+Beaty/@51.5619212,-0.1106783,15z/data=!4m6!3m5!1s0x48761ba1069aab13:0xba3cba2f82ab9a20!8m2!3d51.5619212!4d-0.1106783!16s%2Fg%2F11vwwy3jkv?hl=en&entry=ttu"
              >
                180 Seven Sisters Road, Finsbury Park, London N7 7PX
              </a>
            </p>
            <DynamicSpace gs={5} />
          </div>
        </Box>
        <DynamicDivider length="95%" position="center" color="#7bb2e612" />

        {/* Second Icon Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            borderRadius: "10px",
            padding: "20px 10px",
          }}
        >
          {/* Icon */}
          <IconButton>
          <Link to={"tel:+020 7916 15021"}>
            <LocalPhoneIcon sx={{ color: "#76a1b1" }} />
            </Link>
          </IconButton>

          {/* Content */}
          <div>
            <GlobalHeading
              text="Call Us"
              color={"#76a1b1"}
              variant="h3"
              fontSize={{ xs: "11px", md: "13px" }}
              fontWeight={400}
              letterSpacing="0.04em"
            />
            <DynamicSpace gs={5} />
            <p style={{margin:'0px', marginTop:'7px'}}>
              <a
                style={{
                  textDecoration: 'none',
                  fontSize: '14px',
                  letterSpacing: '0.04em',
                  fontWeight: '600',
                  color: 'black' // Change color to black
                }}
                href="tel:+020 7916 15021"
              >
                +020 7916 15021
              </a>
            </p>
            {/* <GlobalHeading
              text="020 7916 1502"
              variant="h3"
              fontSize={{ xs: "12px", md: "14px" }}
              fontWeight={600}
              letterSpacing="0.04em"
            /> */}
            <DynamicSpace gs={5} />
          </div>
        </Box>

        <DynamicDivider length="95%" position="center" color="#7bb2e612" />

        {/* Third Icon Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            borderRadius: "10px",
            padding: "20px 10px",
          }}
        >
          {/* Icon */}
          <IconButton>
            <Link to={"mailto:info@mastershairbeaty.co"}>
              <ChatIcon sx={{ color: "#76a1b1" }} />
            </Link>
          </IconButton>

          {/* Content */}
          <div>
            <GlobalHeading
              text="Email Us"
              variant="h3"
              color={"#76a1b1"}
              fontSize={{ xs: "11px", md: "13px" }}
              fontWeight={400}
              letterSpacing="0.04em"
            />
            <DynamicSpace gs={5} />
            {/* <GlobalHeading
              text="mastershairbeauty@mail.com"
              variant="h3"
              fontSize={{ xs: "12px", md: "14px" }}
              fontWeight={600}
              letterSpacing="0.04em"
            /> */}
            <p style={{margin:'0px', marginTop:'7px'}}>
              <a
                style={{
                  textDecoration: 'none',
                  fontSize: '14px',
                  display:'block',
                  width:'100px',
                  height:'20px',
                  letterSpacing: '0.04em',
                  fontWeight: '600',
                  color: 'black' // Change color to black
                }}
                href="mailto:info@mastershairbeaty.co"
              >
                info@mastershairbeaty.co
              </a>
            </p>
            <DynamicSpace gs={5} />
          </div>
        </Box>
        <DynamicSpace gs={5} />
      </Box>
    </>
  );
};

export default AboutUs;
