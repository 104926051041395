import React, { MouseEvent } from "react";

const FaqSidebar: React.FC<{ accordionItems: any }> = ({ accordionItems }) => {
    const accordionItem = accordionItems.AccordionValue;
    const handleQuestionClick = (index: number) => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const section = document.getElementById(`section-${index}`);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };
  
    return (
      <div className="accordion_widget" style={{ position: "sticky", top: 0, width: "250px", height: "100vh" }}>
        <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
          {accordionItem.map((item: any, index: number) => (
            <li key={index}>
              <a
                href={`#section-${index}`}
                onClick={handleQuestionClick(index)}
                className="accordion_widget_title"
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  display: "block",
                  color: "black",
                }}
              >
                {item.acc_title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  export default FaqSidebar;