import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

interface Slot {
  id: string;
  staff_member: string;
  service_name: string;
  service_description: string;
  service_price: number;
  service_price_ladies: number;
  service_price_ladies_oap: number | null;
  service_price_children: number | null;
  slot_datetimes: string[];
}

interface UserBooking {
  user_username: string;
  booking_time: string;
  bookedslots: Record<string, Slot[]>;
}

const BookingDetailsTable: React.FC = () => {
  const [bookingDetails, setBookingDetails] = useState<UserBooking[]>([]);

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await axios.get<{ bookingDetails: UserBooking[] }>('http://localhost:3000/booking-details');
        setBookingDetails(response.data.bookingDetails);
      } catch (error) {
        console.error('Error fetching booking details:', error);
      }
    };

    fetchBookingDetails();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table style={{ minWidth: 650 }} aria-label="booking details table">
        
        <TableBody>
          {bookingDetails.map((user) => (
            <>
              {/* <TableRow key={user.user_username}>
                <TableCell colSpan={12} style={{ fontWeight: 'bold' }}>{user.user_username}</TableCell>
              </TableRow> */}
              {Object.entries(user.bookedslots).map(([bookingId, bookingSlots]) => (
                <>
                  {/* <TableRow> */}
                    <TableCell colSpan={12}>{user.user_username}</TableCell>
                    
                    {/* <TableCell colSpan={2}>{bookingSlots[0].staff_member}</TableCell> */}
                  {/* </TableRow> */}
                  {bookingSlots.map((slot) => (
                    <React.Fragment key={`${user.user_username}-${bookingId}-${slot.id}`}>
                      <TableRow>
                      <TableCell colSpan={2}>{user.booking_time}</TableCell>
                        {/* <TableCell colSpan={4}></TableCell> */}
                    <TableCell colSpan={2}>{slot.staff_member}</TableCell>

                        {/* <TableCell colSpan={2}>{slot.service_name}</TableCell> */}
                        <TableCell colSpan={2}>{slot.service_description}</TableCell>
                        <TableCell colSpan={2}>{slot.slot_datetimes}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </>
              ))}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BookingDetailsTable;
