import { Container } from "@mui/material";

interface ContentViewProps {
    fullData: any; // Declare fullData as PageItem type
  }
  const ContentView = ({ fullData }: ContentViewProps) => {
    const content = fullData.content;
    const btnValue = fullData.buttonValues;
    return (
        <>
            <div className='ql-editor' dangerouslySetInnerHTML={{ __html: content }}></div>
                {btnValue &&
                    btnValue.map((btn : any, index: any) => (
                    <a key={index} style={{ fontSize: '13px', color: 'black!important' }} className={btn.btnclass} href={btn.btnlink}>
                        {btn.bname}
                    </a>
            ))}
        </>
    );
}

export default ContentView;