import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollRestorationProps {
  children: React.ReactNode;
}

const ScrollRestoration: React.FC<ScrollRestorationProps> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add('smooth-scroll'); // Add smooth-scroll class to body
    return () => {
      document.body.classList.remove('smooth-scroll'); // Remove smooth-scroll class on component unmount
    };
  }, [pathname]);

  return <>{children}</>;
};

export default ScrollRestoration;
