import React, { useState, useEffect } from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  Box,
  Container,
  Chip,
  Tooltip,
  CircularProgress,
  checkboxClasses,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DynamicSpace from "../../../styles/Helpers/DynamicSpace";
import { useDispatch, useSelector } from "react-redux";
import {
  selectService,
  deselectService,
  selectSelectedServices,
  clearSelectedServices,
} from "../../../stateManagers/servicesSlice";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import ServiceDialog from "./ServiceDialog"; // Import the ServiceDialog component
import { selectStaff } from '../../../stateManagers/staffMembersSlice';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { RootState } from "../../../stateManagers/rootReducer";
import ActiveStaff from "./ActiveStaff";
import { ToastContainer, toast } from 'react-toastify';
import useAuth from "../../../stateManagers/useAuth";
import Footer from "../../../components/Footer";
import Header from "../../../components/header";
import "../../../styles/styles.css";
import { baseURL, baseconUrL } from "../../../stateManagers/apiConfig";

interface Service {
  id: number;
  name: string;
  description: string;
  price_ladies: string;
  price_ladies_oap: string;
  price_children: string | null;
  category: {
    id: number;
    name: string;
  };
}

interface LocationState {
  fromParticularComponent?: boolean;
}

const AppointmentComponent: React.FC = () => {
  const authHook = useAuth();
  const [activeButton, setActiveButton] = useState<"services" | "staff">("services");
  const [expanded, setExpanded] = useState<string | false>(false);
  const [showSelectedServices, setShowSelectedServices] = useState(false);
  const [categories, setCategories] = useState<{ id: number; name: string }[]>([]);
  const [servicesByCategory, setServicesByCategory] = useState<{ [key: number]: Service[] }>({});
  const selectedServicesRedux = useSelector(selectSelectedServices);
  const selectedStaff = useSelector((state: RootState) => state.staffMembers.selectedStaff);
  const [loading, setLoading] = useState(true); // State for loading
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [isToastShown, setIsToastShown] = useState(false);
  const [staffMembers, setStaffMembers] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const delayedCheckAuth = async () => {
      // Introduce a delay before checking authentication
      await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust the delay duration as needed

      const loggedIn = await authHook.verifyToken();
      setIsLoggedIn(loggedIn);

      if (!loggedIn && !isToastShown) {
        navigate('/login');
      }
    };

    delayedCheckAuth();

    return () => {
      // Any cleanup logic you need (if applicable)
    };
  }, [navigate, authHook.verifyToken, isToastShown]);


  useEffect(() => {
    // Fetch categories from the endpoint
    setLoading(true); // Set loading to true when fetching categories
    fetch(`${baseURL}/categories`)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data.categories);
        setLoading(false); // Set loading to false when categories are fetched
      })
      .catch((error) => {
        // console.error("Error fetching categories:", error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  useEffect(() => {
    
    fetch(`${baseconUrL}/api/staff/get`)
  .then((response) => response.json())
  .then((data) => {
    setStaffMembers(data.map((staff: { title: string }) => staff.title));
    setLoading(false); // Set loading to false when staff members are fetched
  })
  .catch((error) => {
    console.error("Error fetching staff members:", error);
    setLoading(false); // Set loading to false in case of error
  });

  });

  useEffect(() => {
    setLoading(true); // Set loading to true when fetching services
    // Fetch services from the endpoint and organize them by category
    fetch(`${baseURL}/services`)
      .then((response) => response.json())
      .then((data) => {
        const services = data.services;
        const servicesByCategory: { [key: number]: Service[] } = {};
        services.forEach((service: Service) => {
          if (!(service.category.id in servicesByCategory)) {
            servicesByCategory[service.category.id] = [];
          }
          servicesByCategory[service.category.id].push(service);
        });
        setServicesByCategory(servicesByCategory);
        setLoading(false); // Set loading to false when services are fetched
      })
      .catch((error) => {
        // console.error("Error fetching services:", error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    // console.log(selectedServicesRedux);
    if (selectedServicesRedux.length > 0) {
      setShowSelectedServices(true);
    } else {
      setShowSelectedServices(false);
    }
  }, [selectedServicesRedux]);

  const handleServiceToggle = (service: Service) => () => {
    const isSelected = selectedServicesRedux.some((item: { id: number; }) => item.id === service.id);

    if (!isSelected) {
      // Dispatch action to select the service
      dispatch(selectService(service));
    } else {
      // Dispatch action to deselect the service
      dispatch(deselectService(service.id));
    }
  };

  

  const handleBookNow = () => {
    if (selectedStaff) {
      // Handle navigation to the calendar or any other booking process here
      navigate("/make-an-appointment", { state: { fromParticularComponent: true } });
    } else {
      // Show an error toast when no staff member is selected
      toast.error("Please select a staff member.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  if (isLoggedIn === null) {
    // Loading state, you can show a loader here
    return null;
  }


  return (
    <><Header /><Box
      display="flex"
      flexDirection="column"
      minHeight="90vh"
      justifyContent="center" // Center the content vertically
      overflow="hidden"
    >
      <Box
        flex="1"
        overflow-x="hidden"
        overflow-y="visible"
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center", // Center the content vertically
        }}
      >

        <DynamicSpace lg={50} md={30} />

        {loading ? ( // Conditionally render loading spinner
          <CircularProgress sx={{ color: '#549eb9' }} />
        ) : (
          <Container
            maxWidth="md"
            style={{ boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px" }}
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              borderRadius: "10px",
              py: 2,
              flex: "0 1 auto", // Allow the Container to shrink, grow, and take up available space
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "flex-start",
              }}
            >
              <DynamicSpace gs={30} />
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Box sx={{ display: "flex", width: "100%", mb: 1 }}>
                  <Button
                    variant="contained"
                    onClick={() => setActiveButton("services")}
                    className="accordion_font_family"
                    sx={{
                      textTransform: "none",
                      width: "50%",
                      borderTopLeftRadius: "50px",
                      borderBottomLeftRadius: "50px",
                      backgroundColor: activeButton === "services" ? "#76a1b1" : "transparent",
                      color: activeButton === "services" ? "#fff" : "#76a1b1",
                      boxShadow: "none",
                      border: activeButton === "staff" ? "1px solid #76a1b1" : "none",
                      '&:hover': {
                        background: '#76a1b1',
                        color: "white"
                      },
                    }}
                  >
                    Services
                  </Button>
                  <Button
                    variant="contained"
                    className="accordion_font_family"
                    onClick={() => setActiveButton("staff")}
                    sx={{
                      textTransform: "none",
                      width: "50%",
                      borderTopRightRadius: "50px",
                      borderBottomRightRadius: "50px",
                      backgroundColor: activeButton === "staff" ? "#76a1b1" : "transparent",
                      color: activeButton === "staff" ? "#fff" : "#76a1b1",
                      boxShadow: "none",
                      border: activeButton === "services" ? "1px solid #76a1b1" : "none",
                      '&:hover': {
                        background: '#76a1b1',
                        color: "white"
                      },
                    }}
                  >
                    Staff
                  </Button>
                </Box>
                <DynamicSpace gs={30} />

                {activeButton === "services" && (
                  <>
                    {categories.map((category) => {
                      const selectedServicesInCategory = servicesByCategory[category.id]?.filter((service) => selectedServicesRedux.some((item: { id: number; }) => item.id === service.id)
                      );
                      const selectedServicesCount = selectedServicesInCategory?.length || 0;

                      return (
                        <Accordion
                          key={category.id}
                          expanded={expanded === `panel${category.id}`}
                          onChange={handleChange(`panel${category.id}`)}
                          sx={{
                            width: "100%",
                            boxShadow: "none",
                            border: "none",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                              borderBottom: "1px solid #e0e0e0",
                              display: "flex",
                              placeContent: "space-between",
                              alignItems: "center",
                              width: "100%"
                            }}
                          >
                            <Box style={{ display: 'flex', placeContent: 'space-between', width: "100%" }} sx={{ mr: 3 }}>
                              <Typography className="accordion_head">{category.name}</Typography>
                              {selectedServicesCount > 0 && (
                                <Chip
                                  label={`${selectedServicesCount} selected`}
                                  size="small"

                                  sx={{
                                    ml: 1,
                                    backgroundColor: "#549eb9", // Change chip color to #549eb9
                                    color: "white",
                                    fontSize: '12px',
                                    fontWeight: '100'
                                  }} />
                              )}
                            </Box>
                          </AccordionSummary>

                          <AccordionDetails className="accordion_wrapper">
                            <List dense>
                              {servicesByCategory[category.id]?.map((service: Service) => (
                                <ListItem className="accordion_wrapper" key={service.id} dense button onClick={handleServiceToggle(service)}>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                                    <Box  sx={{ display: 'flex', alignItems: 'center'}}>
                                      <ListItemIcon className="accordion_icon_wrapper">
                                        <Checkbox
                                          edge="start"
                                          sx={{color:'#76a1b1', 
                                          [`&, &.${checkboxClasses.checked}`]: {
                                            color: '#76a1b1',
                                          },
                                          }}
                                          className="accordion_icon"
                                          checked={selectedServicesRedux.some((item) => item.id === service.id)}
                                          tabIndex={-1}
                                          disableRipple />
                                      </ListItemIcon>
                                      <Typography className="accordion_sub_heading accordion_font_family" variant="body1">{service.description}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                      <Tooltip className="accordion_font_family accordion_tool_tip" title="Duration of the Service." enterDelay={200} leaveDelay={100}>
                                        <Chip
                                          icon={<TimelapseIcon />}
                                          label="45 minutes"
                                          size="small"
                                          variant="outlined"
                                          className="accordion_chip"
                                          sx={{
                                            mr: 3,
                                            color: "black",
                                            fontSize: '12px',
                                            fontWeight: '100'
                                          }} />
                                      </Tooltip>

                                      {service.price_ladies && (
                                        <Typography variant="body1" className="accordion_font_family accordion_price">
                                          Ladies {service.price_ladies && service.price_ladies_oap && service.price_children ? ` (${service.price_ladies})` : ''}:{' '}
                                          £{service.price_ladies}
                                        </Typography>
                                      )}
                                      {service.price_ladies_oap && (
                                        <>
                                          {service.price_ladies && <> `&nbsp;`</>}
                                          <Typography variant="body1" className="accordion_font_family accordion_price">
                                            Ladies OAP {service.price_ladies && service.price_ladies_oap && service.price_children ? ` (${service.price_ladies_oap})` : ''}:{' '}
                                            £{service.price_ladies_oap}
                                          </Typography>
                                        </>
                                      )}
                                      {service.price_children && (
                                        <>
                                          {(service.price_ladies && service.price_ladies_oap) && <> `&nbsp;`</>}
                                          <Typography variant="body1" className="accordion_font_family accordion_price">
                                            Children {service.price_ladies && service.price_ladies_oap && service.price_children ? ` (${service.price_children})` : ''}:{' '}
                                            £{service.price_children}
                                          </Typography>
                                        </>
                                      )}
                                    </Box>
                                  </Box>
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </>
                )}

                {/* Render staff list */}
                {activeButton === "staff" && <ActiveStaff staffMembers={staffMembers} />}
                <ToastContainer />

                {/* Render the ServiceDialog */}
                {showSelectedServices && <ServiceDialog handleBookNow={handleBookNow} showSelectDateButton={true} />}
              </Box>
              <DynamicSpace gs={35} />
            </Box>

          </Container>
        )}
        <DynamicSpace gs={65} />
      </Box>
    </Box><Footer /></>
  );
};

export default AppointmentComponent;
