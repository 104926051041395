import React from 'react';
import FAQsAccordion from './faqsAccordion';

interface FaqsContentProps {
  fullData: any; // Declare fullData as PageItem type
}

const FaqsContent = ({ fullData }: FaqsContentProps) => {
  return (
    <div>
      {/* Use the AdvancedAccordion component and pass the accordion items */}
      <FAQsAccordion fullData={fullData}   />
    </div>
  );
};

export default FaqsContent;
