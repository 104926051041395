import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import "../styles/footer.css"; // Import the CSS file
import payment from "./payment.png"; // Import the image file
import Heading from "../styles/components/heading";
import Navigationlink from "../styles/components/navigationlink";
import "../styles/styles.css";
import DynamicDivider from "../styles/Helpers/DynamicDivider";
import DynamicSpace from "../styles/Helpers/DynamicSpace";
import { useSelector } from "react-redux";

const Footer: React.FC = () => {
  const websiteUrl = useSelector(
    (state: any) => (state || {}).options.websiteUrl
  );

  return (
    <>
      <div className="footer-container">
        {" "}
        {/* Container for the footer */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <Heading text="LOCATIONS & APPOINTMENTS" />
              <a className="navigation-link" href="/Booking">
                Book Online
              </a>
              <a className="navigation-link" href="/our-location">
                Our Location
              </a>
              <a className="navigation-link" href="/service">
                Our Services
              </a>
              <a className="navigation-link" href="/gift-cards">
                Gift Cards
              </a>
              <a className="navigation-link" href="/salon-policies">
                Salon Policies
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <Heading text="HELP AND INFORMATION" />
              <a className="navigation-link" href="/contact-us">
                Contact Us
              </a>
              <a className="navigation-link" href="/faq">
                FAQs
              </a>
              <a
                className="navigation-link"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfWn8QVE8H4lbnbyfPsoDmMGlDDa8db_Q4G0PM45118X64eIA/viewform"
              >
                Hair Model Application
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <Heading text="MASTER'S HAIR & BEAUTY SALONS" />
              <Navigationlink
                to="/about"
                primaryText="With 5-Star Reviews, Trust Master's Hair and Beauty Salon in London  with your Hair, Hair Colour, Balayage, Highlights and Hair Removal, services."
                disabled={true}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="footer-copyright">
        <DynamicDivider length="100%" position="center" color="#80808094" />
        <DynamicSpace gs={9} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Display items in a column
            gap: "2px",
            justifyContent: "space-between",
            "@media (min-width:600px)": {
              gap: "15px",
              flexDirection: "row", // Change direction to row for wider screens
            },
          }}
        >
          <Grid item xs={6} sm={6} md={6}>
            <a
              style={{
                color: "#ffffffc7",
                fontFamily: "Futura, sans-serif",
                fontSize: "9px",
              }}
              href={websiteUrl}
            >
              © MASTER'S HAIR & BEAUTY
            </a>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <a
              href="https://www.peopleperhour.com/freelancer/technology-programming/arti-bhadwal-small-team-of-creative-designers-ywnwzwy"
              style={{
                color: "#ffffffc7",
                fontFamily: "Futura, sans-serif",
                letterSpacing: "0.07rem",
                fontSize: "10px",
                margin: "0px",
              }}
            >
              Designed by Freelancer Developer
            </a>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default Footer;
