import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import DynamicSpace from "../styles/Helpers/DynamicSpace";
import { BaseUrl } from "../stateManagers/apiConfig";

interface Post {
  id: number;
  title: string;
  body: string;
  author: string;
  createdAt: string;
}

const SinglePostPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    axios
      .get<Post>(`${BaseUrl}/api/post/single/${id}`)
      .then((response) => {
        setPost(response.data);
      })
      .catch((error) => {
        console.error("Error fetching post:", error);
      });
  }, [id]);

  if (!post) {
    return (
      <Box
        display="flex"
        alignItems="center"
        minHeight="90vh"
        justifyContent="center" // Center the content vertically
        overflow="hidden"
      >
        <CircularProgress sx={{ color: "#549eb9" }} />
      </Box>
    );
  }

  return (
    <div>
      <div className="main-container">
        <div
          className="banner-area"
          style={{
            background:
              "url(https://appify.vercel.app/static/media/gift.d5993ae6a547084c8af1.jpg)",
            height: "300px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={2}></Grid>
            <Grid item lg={8}>
              <div
                className="shadow p-5 mb-5"
                style={{ marginTop: "-80px", background: "white" }}
              >
                <Typography variant="h4">{post.title}</Typography>
                <DynamicSpace gs={20} />
                <Typography
                  dangerouslySetInnerHTML={{ __html: post.body }}
                ></Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
        <DynamicSpace gs={60} />
      </div>
    </div>
  );
};

export default SinglePostPage;
