import React, { useState, useEffect } from 'react';
import { Typography, Button, Box, Divider, Container } from '@mui/material';
import ServiceDialog from './ServiceDialog';
import { useSelector, useDispatch } from 'react-redux';
import servicesSlice, { selectSelectedServices } from '../../../stateManagers/servicesSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DynamicSpace from '../../../styles/Helpers/DynamicSpace';
import GlobalHeading from '../../../styles/components/GlobalHeading';
import { RootState } from '../../../stateManagers/rootReducer';
import { Navigate } from 'react-router-dom';
import Header from '../../../components/header';
import Footer from '../../../components/Footer';
import { baseURL } from '../../../stateManagers/apiConfig';

interface SelectedSlot {
  date: Date;
  slot: number;
  formattedSlot: string;
}

interface BookedSlot {
  bookedSlots: string[];
}

const CalendarComponent: React.FC = () => {
  const [selectedSlots, setSelectedSlots] = useState<SelectedSlot[]>([]);
  const [showServiceDialog, setShowServiceDialog] = useState(false);
  const selectedServicesRedux = useSelector(selectSelectedServices);
  const [slotsToSelect, setSlotsToSelect] = useState(1);
  const selectedStaff = useSelector((state: RootState) => state.staffMembers.selectedStaff);

  const [bookedSlots, setBookedSlots] = useState<string[]>([]);
  

  useEffect(() => {
    if (selectedServicesRedux.length > 0) {
      setShowServiceDialog(true);
      setSlotsToSelect(selectedServicesRedux.length);
    } else {
      setShowServiceDialog(false);
      setSlotsToSelect(1);
    }
  }, [selectedServicesRedux]);

  useEffect(() => {
    if (selectedStaff) {
      fetchBookedSlots(selectedStaff);
    }
  }, [selectedStaff]);


  const fetchBookedSlots = async (selectedStaff: string) => {
    try {
      const response = await fetch(`${baseURL}/bookedSlots`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ staff_member: selectedStaff })
      });
      
      const data = await response.json();
      setBookedSlots(data.bookedSlots);
    } catch (error) {
      // console.error('Error fetching booked slots:', error);
      // Handle error appropriately (e.g., show error message to the user)
    }
  };
  

  const makeBooking = () => {
    // Handle booking logic
  };

  
  const handleSlotClick = (date: Date, slot: number) => {
    if (selectedServicesRedux.length <= 0) {
      toast.error('Please at least choose one service before selecting a slot.');
      return; // Do not allow selection if no services are selected
    }
    const selectedDate = new Date(date);
    const hours = selectedDate.getHours();
    const minutes = selectedDate.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    const formattedSlot = `${selectedDate.getMonth() + 1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}, ${formattedHours}:${formattedMinutes}:00 ${ampm}`;
  
    const isSlotBooked = bookedSlots && bookedSlots.some(bookedSlot => bookedSlot === formattedSlot);
  
    if (isSlotBooked) {
      return; // Do not allow selection of booked slots
    }
  
    const isSlotSelected = selectedSlots.some(
      (selectedSlot) =>
        selectedSlot.date.toDateString() === selectedDate.toDateString() &&
        selectedSlot.slot === slot
    );
  
    if (isSlotSelected) {
      setSelectedSlots((prevSelectedSlots) =>
        prevSelectedSlots.filter(
          (selectedSlot) =>
            !(
              selectedSlot.date.toDateString() === selectedDate.toDateString() &&
              selectedSlot.slot === slot
            )
        )
      );
    } else {
      if (selectedSlots.length >= slotsToSelect) {
        toast.error('You cannot select more slots than the total number of services.');
        return; // Do not allow selection if max slots already selected
      }
      
      setSelectedSlots((prevSelectedSlots) => [
        ...prevSelectedSlots,
        { date: selectedDate, slot, formattedSlot },
      ]);
    }
  };
  
  if (selectedStaff == null) {
    return <Navigate to="/booking" />;
  }


  

  return (
    <><Header /><Box minHeight="90vh" sx={{ overflowY: 'auto', maxHeight: '600px', width: '100%' }}>
      <DynamicSpace gs={35} />
      <Container>
        <GlobalHeading text='Select a Date' className='typography-center' letterSpacing='1.5px' />
        <DynamicSpace gs={15} />




        {[...Array(30)].map((_, index) => {
          const today = new Date();
          const date = new Date(today);
          date.setDate(today.getDate() + index);

          return (
            <Box key={index}>
              <Box p={2} mb={2} display="flex" flexDirection="row">
                <Box style={{ textAlign: 'center' }} mb={1} mr={2}>
                  <Typography sx={{ fontSize: '19px' }}>{date.getDate()}</Typography>
                  <Typography sx={{ fontSize: '14px' }}>
                    {date.toLocaleString('default', { weekday: 'short' }).toUpperCase()}
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    {date.toLocaleString('default', { month: 'short' }).toUpperCase()}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: '5px 10px',
                    alignItems: 'center',
                    background: '#c4c4c43d',
                    borderRadius: '5px',
                  }}
                >
                  {[...Array(25)].map((_, slotIndex) => {
                    const timeInMinutes = slotIndex * 45 + 630;
                    if (timeInMinutes <= 1170) {
                      const hours = Math.floor(timeInMinutes / 60);
                      const minutes = timeInMinutes % 60;
                      const ampm = hours >= 12 ? 'PM' : 'AM';
                      const displayHours = hours % 12 || 12;
                      const displayMinutes = String(minutes).padStart(2, '0');
                      const isSelected = selectedSlots.some(
                        (selectedSlot) => selectedSlot.date.toDateString() === date.toDateString() &&
                          selectedSlot.slot === slotIndex
                      );

                      const slotTime = new Date(date.getTime());
                      slotTime.setHours(hours, minutes, 0, 0);

                      const formattedSlot = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}, ${displayHours}:${displayMinutes}:00 ${ampm.toLowerCase()}`;
                      const isSlotBooked = bookedSlots ? bookedSlots.some(bookedSlot => bookedSlot === formattedSlot) : false;


                      return (
                        <Button
                          key={slotIndex}
                          size="small"
                          sx={{
                            minWidth: 'auto',
                            width: 'auto',
                            height: '27px',
                            margin: 0,
                            padding: '0px 8px',
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                            border: isSelected ? '0px' : '1px solid #549eb9',
                            color: isSlotBooked ? 'white!important' : isSelected ? 'white!important' : '#1e92bd',
                            backgroundColor: isSlotBooked ? 'red' : isSelected ? '#549eb9' : 'inherit',
                            mr: 1,
                            ml: 1,
                            mb: 1,
                            mt: 1,
                            '&:hover': {
                              backgroundColor: isSlotBooked ? 'red' : isSelected ? '#1e92bd' : '#549eb9',
                              color: isSlotBooked ? 'white!important' : isSelected ? 'white!important' : 'white!important',
                            },
                            pointerEvents: isSlotBooked ? 'none' : 'auto', // Disable pointer events for booked slots
                          }}
                          onClick={() => !isSlotBooked && handleSlotClick(slotTime, slotIndex)}
                          disabled={isSlotBooked} // Disable button for booked slots
                        >
                          {`${displayHours}:${displayMinutes} ${ampm}`}
                        </Button>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Box>
              </Box>
              <Divider />
            </Box>
          );
        })}




        {/* <Button onClick={logSelectedSlots}>Log Selected Slots</Button> */}
        {showServiceDialog && (
          <ServiceDialog handleBookNow={makeBooking} showSelectDateButton={false} selectedSlots={selectedSlots} />
        )}
        <ToastContainer />
      </Container>
    </Box><Footer /></>
  )};
  export default CalendarComponent;