import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Heading6 from "../../styles/components/Heading6";
import GlobalHeading from "../../styles/components/GlobalHeading";
import DynamicSpace from "../../styles/Helpers/DynamicSpace";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CastIcon from "@mui/icons-material/Cast";
import { useNavigate } from "react-router-dom";
import SpecialOffersAndAbout from "./SpecialOffersAndAbout";

const BookingForm: React.FC = () => {
  const [showAppointment, setShowAppointment] = useState(false);
  const navigate = useNavigate();
  
  const handleBooking = () => {
    navigate("/make-an-appointment")
  };

  return (
    <div style={{minHeight:"70vh"}}>
      <Box
        sx={{
          height: 130,
          background:
            "#76a1b1",
        }}
      ></Box>
      <Container maxWidth="md">
        <Box
          sx={{
            mt: -5,
            position: "relative",
            zIndex: 1,
            backgroundColor: "white",
            padding: "30px 20px",
            borderRadius: "10px",
            boxShadow:"rgba(0, 0, 0, 0.15) 0px 5px 15px 0px"
          }}
        >
          <Heading6 text="Welcome to" />
          <DynamicSpace gs={8} />
          <GlobalHeading
            text="MASTER'S HAIR & BEAUTY"
            variant="h3"
            fontSize={{ xs: "14px", md: "16px" }}
            fontWeight={600}
            letterSpacing="0.04em"
          />
          <DynamicSpace gs={15} />
          <form>
            <Box sx={{ display: "flex", justifyContent:"center", width:"40%" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  flexGrow: 1,
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  padding: "8px 0px", // Adjust padding as needed
                  backgroundColor:"#76a1b1",
                  ':hover': {
                    bgcolor: '#6590a0', // theme.palette.primary.main
                    color: 'white',
                  },
                  fontSize: "10px", // Adjust font size for mobile
                  "@media (min-width: 600px)": {
                    fontSize: "14px", // Adjust font size for larger screens
                  },
                }}
                onClick={handleBooking}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexGrow={1}
                >
                  Make a Booking
                </Box>
                <Box position="absolute" right={20} sx={{ display: { xs: "none", md: "block" } }}>
                  <ArrowForwardIcon
                    sx={{ marginTop: "0px", fontSize: "16px" }}
                  />
                </Box>
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                sx={{
                  flexGrow: 1,
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:"#468bbf",
                  justifyContent: "center",
                  position: "relative",
                  padding: "8px 0px",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexGrow={1}
                >
                  Buy a Gift Voucher
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  position="absolute"
                  right={20}
                >
                  <CastIcon sx={{ fontSize: "16px" }} />
                </Box>
              </Button> */}
            </Box>
          </form>
          <SpecialOffersAndAbout />
        </Box>
        <DynamicSpace gs={50} />
      </Container>
    </div>
  );
};

export default BookingForm;
