import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import DrawerLinks from "./drawerlinks";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import GlobalAnchor from "../styles/components/GlobalAnchor";
import "../styles/styles.css";
import OptionsValue from "../stateManagers/options";
import { useSelector } from "react-redux";
import useAuth from "../stateManagers/useAuth";

const theme = createTheme({
  typography: {
    fontFamily: ["Futura", "sans-serif"].join(","),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 200,
          fontStyle: "normal",
          fontSize: "10px",
          color: "white",
          transition: "color .2s ease-in-out",
          letterSpacing: ".2em",
          textTransform: "uppercase",
          lineHeight: "24px",
          textAlign: "center",
          fontFamily: "Futura, sans-serif",
        },
      },
    },
  },
});

const SubHeader: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          backgroundColor: "#76a1b1",
          padding: "10px 13px",
          textAlign: "center",
        }}
      >
        <Container maxWidth="md">
          <Link className="link" to="/booking">
            BOOK ONLINE | TAP HERE
          </Link>
        </Container>
      </div>
    </ThemeProvider>
  );
};

const Header: React.FC = () => {
  const authHook = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const checkLoggedIn = async () => {
      const isLoggedIn = await authHook.verifyToken();
      setIsLoggedIn(isLoggedIn);
      // Use the value of isLoggedIn here or set it to state if needed
    };

    checkLoggedIn();
  }, [authHook]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const instagram = useSelector(
    (state: any) => (state || {}).options.instagram
  );
  const twitter = useSelector((state: any) => (state || {}).options.twitter);
  const facebook = useSelector((state: any) => (state || {}).options.facebook);

  return (
    <div>
      <OptionsValue />
      <SubHeader />
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(121 127 132 / 34%)",
        }}
      >
        <Toolbar>
          <IconButton onClick={toggleDrawer} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Toolbar
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <GlobalAnchor
              text="MASTER'S HAIR & BEAUTY"
              variant="h6"
              fontSize={{ xs: "12px", md: "16px" }}
              letterSpacing="0.2em"
              lineHeight={{ xs: "1.5", md: "1.5" }}
              color="black"
              href="/"
              underline={false}
            />
          </Toolbar>
          <div style={{ width: theme.spacing(7) }} />
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: "22vw",
            minWidth: "200px",
            padding: "10px 15px", // Adjusted padding
            background:
              "linear-gradient(to bottom, rgb(0 0 0 / 79%) 0%, rgb(0 0 0 / 89%) 100%)", // Background gradient
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "8px",
          }}
        >
          {" "}
          {/* Adjusted padding */}
          <IconButton onClick={toggleDrawer} sx={{ color: "#ffffffc7" }}>
            <CloseIcon />
          </IconButton>
        </div>
        {/* <DrawerLinks href="/" text="Home" closeDrawer={closeDrawer} />
        <DrawerLinks
          href="/"
          text=""
          closeDrawer={closeDrawer}
        />
        <DrawerLinks
          href="/gift-cards"
          text="Gift Cards"
          closeDrawer={closeDrawer}
        /> */}
         <a href="/" className="menu-achnor">
        <div className="MuiListItemText-root css-tlelie-MuiListItemText-root">
          <p className="MuiTypography-root MuiTypography-body2 css-e784if-MuiTypography-root" 
          style={{color: "white", fontSize: "12px", letterSpacing: "0.2em", fontWeight: "400"}}>
       Home</p></div>
        </a>
        <hr className="menu-hr"></hr>
        <a href="/our-location" className="menu-achnor">
        <div className="MuiListItemText-root css-tlelie-MuiListItemText-root">
          <p className="MuiTypography-root MuiTypography-body2 css-e784if-MuiTypography-root" 
          style={{color: "white", fontSize: "12px", letterSpacing: "0.2em", fontWeight: "400"}}>
       Our Location</p></div>
        </a>
        <hr className="menu-hr"></hr>
        <a href="/gift-cards" className="menu-achnor">
        <div className="MuiListItemText-root css-tlelie-MuiListItemText-root">
          <p className="MuiTypography-root MuiTypography-body2 css-e784if-MuiTypography-root" 
          style={{color: "white", fontSize: "12px", letterSpacing: "0.2em", fontWeight: "400"}}>
        Gift Cards</p></div>
        </a>
        <hr className="menu-hr"></hr>
        {isLoggedIn && ( // Conditionally render the dashboard link based on isLoggedIn state
          <DrawerLinks
            href="/dash"
            text="Dashboard"
            closeDrawer={closeDrawer}
          />
        )}
        {!isLoggedIn && ( // Conditionally render the dashboard link based on isLoggedIn state
          <>
            <DrawerLinks
              href="/register"
              text="Register"
              closeDrawer={closeDrawer}
            />
            <DrawerLinks href="/login" text="Login" closeDrawer={closeDrawer} />
          </>
        )}
        {/* <DrawerLinks href="/" text="Search" closeDrawer={closeDrawer} /> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "auto",
            borderTop: "0.5px solid #ffffff75",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginRight: "auto",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <IconButton
              href={facebook}
              sx={{ color: "#ffffffc7", fontSize: "10px" }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              href={twitter}
              sx={{ color: "#ffffffc7", fontSize: "20px" }}
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              href={instagram}
              sx={{ color: "#ffffffc7", fontSize: "20px" }}
            >
              <InstagramIcon />
            </IconButton>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
