import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import GlobalHeading from "../../styles/components/GlobalHeading";
import DynamicSpace from "../../styles/Helpers/DynamicSpace";
import { ListItem, ListItemIcon } from "@mui/material";
import { FiberSmartRecord } from "@mui/icons-material";

const SpecialOffers: React.FC = () => {
  const [showSpecialDeals, setShowSpecialDeals] = useState(false);

  const handleViewAllClick = () => {
    // Toggle the state to show/hide the special deals
    setShowSpecialDeals(!showSpecialDeals);
  };

  return (
    <>
      <GlobalHeading
        text="Special Offers"
        variant="h3"
        fontSize={{ xs: "14px", md: "16px" }}
        fontWeight={600}
        letterSpacing="0.04em"
      />
      <DynamicSpace gs={10} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        {/* Icon */}
        <IconButton>
          <StarBorderIcon sx={{ color: "#76a1b1" }} />
        </IconButton>

        {/* Content */}
        <div onClick={handleViewAllClick} style={{ cursor: "pointer" }}>
          <GlobalHeading
            text="Click here to view all special offers"
            color={"#76a1b1"}
            variant="h3"
            fontSize={{ xs: "12px", md: "14px" }}
            fontWeight={600}
            letterSpacing="0.04em"
          />
          <DynamicSpace gs={7} />
          <GlobalHeading
            text="Don't miss our special deals."
            variant="h3"
            fontSize={{ xs: "11px", md: "13px" }}
            fontWeight={400}
            letterSpacing="0.04em"
          />
          <DynamicSpace gs={5} />
        </div>

        {/* Additional content to show below */}
      </Box>
      {showSpecialDeals && (
          <>
            <DynamicSpace gs={15} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column', // Align entries vertically
                gap: '10px', // Gap between entries
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: '10px',
                padding: '20px',
                paddingLeft:'10px'
              }}
            >
              {/* List of special deals */}
              <ListItem>
                <ListItemIcon sx={{minWidth:'35px'}}>
                  <FiberSmartRecord sx={{ color: '#76a1b1', fontSize:'15px' }} />
                </ListItemIcon>
                <Typography sx={{fontSize:'13px'}} variant="body1">
                  Student Discount of 10% on hair cutting.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{minWidth:'35px'}}>
                  <FiberSmartRecord sx={{ color: '#76a1b1', fontSize:'15px' }} />
                </ListItemIcon>
                <Typography sx={{fontSize:'13px'}} variant="body1">
                  Men's Hair Cutting discount of 5% available only to the end of the month.
                </Typography>
              </ListItem>
              {/* Add more list items as needed */}
            </Box>
          </>
        )}
    </>
  );
};

export default SpecialOffers;
