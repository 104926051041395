import React, { useState, useEffect } from 'react';
import { Box, Container, Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ToastContainer } from 'react-toastify';
import "../../styles/styles.css";
import { baseURL } from '../../stateManagers/apiConfig';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';

interface Service {
  id: number;
  name: string;
  description: string;
  price_ladies: number | null;
  price_ladies_oap: number | null;
  price_children: number | null;
  price_men: number | null;
  category: {
    id: number;
    name: string;
  };
}

const ServicesData: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<{ id: number; name: string }[]>([]);
  const [servicesByCategory, setServicesByCategory] = useState<{ [key: number]: Service[] }>({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${baseURL}/categories`);
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        const data = await response.json();
        setCategories(data.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchServices = async () => {
      try {
        const response = await fetch(`${baseURL}/services`);
        if (!response.ok) {
          throw new Error('Failed to fetch services');
        }
        const data = await response.json();
        const services = data.services;
        const servicesByCategory: { [key: number]: Service[] } = {};
        services.forEach((service: Service) => {
          if (!(service.category.id in servicesByCategory)) {
            servicesByCategory[service.category.id] = [];
          }
          servicesByCategory[service.category.id].push(service);
        });
        setServicesByCategory(servicesByCategory);
      } catch (error) {
        console.error('Error fetching services:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    fetchServices();
  }, []);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Container>
        <div className=' '>
        <br />
        <Container maxWidth="md" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px', borderRadius:'5px' }}>
            <DynamicSpace gs={20} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {categories.map((category) => (
                <Accordion key={category.id} sx={{ width: '100%', boxShadow: 'none', border: 'none' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderBottom: '1px solid #e0e0e0', display: 'flex', placeContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography className="accordion_head service_heading">{category.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion_wrapper">
                    <List dense>
                    <table style={{ width: '100%' }}>
                    <thead>
                        <tr className='services_table_data'>
                        <th style={{ width: '60%', paddingLeft: '7px' }}>
                            <Typography className="accordion_sub_heading accordion_font_family" variant="body1">Service Name</Typography>
                        </th>
                        <th style={{ width: '30%', paddingLeft: '7px' }}>
                            <Typography variant="body1" className="accordion_font_family accordion_price">
                            Service For
                            </Typography>
                        </th>
                        <th style={{ width: '10%', paddingLeft: '7px' }}>
                            <Typography variant="body1" className="accordion_font_family accordion_price">
                            Price
                            </Typography>
                        </th>
                        </tr>
                    </thead>
                    <DynamicSpace gs={5} />

                    <tbody>
                        {servicesByCategory[category.id]?.map((service: Service) => (
                        <tr key={service.id} className='services_table_data'>
                            <td style={{ width: '60%' }}>
                            <Typography className="accordion_sub_heading accordion_font_family" variant="body1">{service.description}</Typography>
                            </td>
                            <td style={{ width: '30%',  }}>
                            <Typography variant="body1" className="accordion_font_family accordion_price">
                                {service.price_ladies !== null && <span>Ladies</span>}
                                {service.price_ladies_oap !== null && <span>Ladies OAP</span>}
                                {service.price_children !== null && <span>Children</span>}
                                {service.price_men !== null && <span>Men's</span>}
                            </Typography>
                            </td>
                            <td style={{ width: '10%' }}>
                            <Typography variant="body1" className="accordion_font_family accordion_price">
                                {service.price_ladies !== null && <div>£{service.price_ladies}</div>}
                                {service.price_ladies_oap !== null && <div>£{service.price_ladies_oap}</div>}
                                {service.price_children !== null && <div>£{service.price_children}</div>}
                                {service.price_men !== null && <div>£{service.price_men}</div>}
                            </Typography>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
              <ToastContainer />
            </Box>
            <DynamicSpace gs={20} />
          </Box>
          <DynamicSpace gs={20} />
        </Container>
        </div>
        </Container>
      )}
    </>
  );
};

export default ServicesData;
