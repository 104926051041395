
// ProfileComponent.tsx
import React from 'react';

const MakeBooking: React.FC = () => {
  return (
    <div style={{minHeight:'10vh'}}>
      <h5>Please Wait a Moment....</h5>
    </div>
  );
};

export default MakeBooking;
