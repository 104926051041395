import React, { useState, useEffect } from "react";
import DynamicSpace from "../../../styles/Helpers/DynamicSpace";
import { CircularProgress } from "@mui/material";
import { color } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "../../../stateManagers/rootReducer";
import { baseURL } from "../../../stateManagers/apiConfig";

interface Booking {
  user_username: string;
  booking_time: string;
  booking_date: string;
  bookedslots: { [key: string]: BookedSlot[] };
}

interface BookedSlot {
  id: string;
  booking_id: string;
  staff_member: string;
  service_id: number;
  service_name: string;
  service_description: string;
  service_price: number;
  service_price_ladies: number;
  service_price_ladies_oap: number | null;
  service_price_children: number | null;
  slot_datetimes: string;
}

const Profile: React.FC = () => {
  const [latestBooking, setLatestBooking] = useState<Booking | null>(null);
  const { userId, username } = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch booking details from the API
    fetch(`${baseURL}/userbookingDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: userId }), // Replace 123 with the actual user ID
    })
      .then((response) => response.json())
      .then((data) => {
        // Get the latest booking by selecting the last entry in the array
        if (data.bookingDetails.length > 0) {
          const latestBookingDetails =
            data.bookingDetails[data.bookingDetails.length - 1];
          setLatestBooking(latestBookingDetails);
        }
        setLoading(false); // Set loading to false after fetching data
      })
      .catch((error) => {
        console.error("Error fetching booking details:", error);
        // Handle error
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  return (
    <div>
      <DynamicSpace gs={15} />
      {!loading && !latestBooking && (
        <>
          <h3 style={{color:"#76a1b1"}}>Welcome {username} ...</h3>
          <DynamicSpace gs={15} />
          <p>Your haven't make an appointment so far!</p>
          <p>Please make an appointment by selecting Make Booking from the left menu!</p>
        </>
      )}
      {(!loading && !latestBooking) ||
        (latestBooking && (
          <>
            <h3>Welcome {username} ...</h3>
            <DynamicSpace gs={15} />
            <p>Your Upcoming Booking Appointment !</p>
          </>
        ))}
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "30vh",
          }}
        >
          <CircularProgress sx={{ color: "#549eb9" }} />
        </div>
      )}
      {/* Render latest booking details */}
      {!loading && latestBooking && (
        <div>
          <p>Booked on {latestBooking.booking_date}</p>
          {/* Render booked slots in a table */}
          <table>
            <thead>
              <tr>
                {/* <th style={{ paddingRight: '30px' }}>Booking ID</th> */}
                <th style={{ paddingRight: "30px" }}>Service's Selected</th>
                <th style={{ paddingRight: "30px" }}>Staff Member</th>
                <th>Slot Details</th>
              </tr>
              <DynamicSpace gs={10} />
            </thead>
            <tbody>
              {/* Get the last entry from the bookedslots object */}
              {Object.entries(latestBooking.bookedslots)
                .reduce<BookedSlot[]>((_, [__, value]) => value, [])
                .map((slot: BookedSlot) => (
                  <React.Fragment key={slot.id}>
                    <tr>
                      {/* <td style={{ paddingRight: '30px' }}>{slot.booking_id}</td> */}
                      <td style={{ paddingRight: "30px" }}>
                        {slot.service_description}
                      </td>
                      <td style={{ paddingRight: "30px" }}>
                        {slot.staff_member}
                      </td>
                      <td>{slot.slot_datetimes}</td>
                    </tr>
                    <DynamicSpace gs={10} /> {/* Add space between rows */}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Profile;
