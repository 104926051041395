import { combineReducers } from '@reduxjs/toolkit';
import calendarReducer from './calendarSlice';
import servicesReducer from './servicesSlice';
import staffMembersReducer from './staffMembersSlice';
import authReducer from './authSlice'; 
import optionsReducer from './optionsSlice';
import userReducer from './userSlice';
import pageDataSlice from './pageDataSlice';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  services: servicesReducer, // Make sure services slice is included here
  staffMembers: staffMembersReducer,
  auth: authReducer,
  options: optionsReducer,
  user: userReducer,
  fullpagedata: pageDataSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
