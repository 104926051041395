import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, CardHeader, CardMedia, CardContent, Box, CircularProgress } from '@mui/material';
import DynamicSpace from '../styles/Helpers/DynamicSpace';
import gift from './images/gift.jpg';
import Heading2 from '../styles/components/Heading2';
import { baseconUrL } from '../stateManagers/apiConfig';

interface StaffMember {
  id: number;
  title: string;
  body: string;
}

interface GiftCardProps {
  title: string;
  description: string;
}

const GiftCard: React.FC<GiftCardProps> = ({ title, description }) => {
  const image = gift;
  return (
    <Grid item xs={4} lg={4}>
      <Card sx={{ margin: '0 auto', border: 'none', boxShadow: 'none', mx: '5px' }}>
        <CardMedia component="img" height={(400 / 16) * 11} image={image} alt={title} />
        <CardHeader
          title={title}
          sx={{ margin: '0px', paddingBottom: '0px' }}
          titleTypographyProps={{
            variant: 'h5',
            fontFamily: 'Arial, sans-serif',
            fontSize: '18px',
            letterSpacing: '.2em',
            paddingTop: '5px',
            paddingBottom: '0px',
          }}
        />
        <CardContent style={{ paddingBottom: '0px' }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ margin: '0px', paddingTop: '5px' }}
            style={{
              fontFamily: 'Futura, sans-serif',
              fontSize: '14px',
              lineHeight: '1.65',
              paddingTop: '0px!important',
              paddingBottom: '0px',
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const OurTeam: React.FC = () => {
  const [staffMembers, setStaffMembers] = useState<StaffMember[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isServerDown, setIsServerDown] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseconUrL}/api/staff/get`)
      .then((response) => {
        if (!response.ok) {
          setIsServerDown(true);
          setIsLoading(false);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setStaffMembers(data || []);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching staff members:', error);
        setIsServerDown(true);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" minHeight="90vh" justifyContent="center" overflow="hidden">
        <CircularProgress sx={{ color: "#549eb9" }} />
      </Box>
    );
  }

  if (staffMembers.length === 0) {
    return (
      <Box display="flex" alignItems="center" minHeight="90vh" justifyContent="center" overflow="hidden">
        <Typography variant="h4" color="text.primary">
          No staff members found.
        </Typography>
      </Box>
    );
  } else if (isServerDown) {
    return (
      <Box display="flex" alignItems="center" minHeight="90vh" justifyContent="center" overflow="hidden">
        <Typography variant="h4" color="text.primary">
          Something went wrong please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <section style={{ position: 'relative', padding: '65px 10% 65px 10%' }}>
      <Grid item xs={12} lg={4} style={{ textAlign: 'center' }}>
        <Heading2 text="OUR TEAM" />
        <DynamicSpace gs={50} />
      </Grid>
      <Grid container display={'flex'} flexDirection={'row'}>
        {staffMembers.map((staff) => (
          <GiftCard key={staff.id} title={staff.title} description={staff.body} />
        ))}
      </Grid>
      <DynamicSpace gs={50} />
    </section>
  );
};

export default OurTeam;