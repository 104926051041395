// staffMembersSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StaffMembersState {
  selectedStaff: string | null;
}

const initialState: StaffMembersState = {
  selectedStaff: null,
};

const staffMembersSlice = createSlice({
  name: 'staffMembers',
  initialState,
  reducers: {
    selectStaff: (state, action: PayloadAction<string>) => {
      state.selectedStaff = action.payload;
    },
    deselectStaff: (state) => {
      state.selectedStaff = null;
    },
  },
});

export const { selectStaff, deselectStaff } = staffMembersSlice.actions;
export default staffMembersSlice.reducer;