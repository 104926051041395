// ProfileComponent.tsx
import React from 'react';

const LogOut: React.FC = () => {
  return (
    <div style={{minHeight:'10vh'}}>
      <h5>Logging Out!.</h5>
    </div>
  );
};

export default LogOut;
