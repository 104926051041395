// optionsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OptionsState {
  siteTitle: string | null;
  instagram: string | null;
  websiteUrl: string | null;
  facebook: string | null;
  twitter: string | null;
}

const initialState: OptionsState = {
  siteTitle: null,
  instagram: null,
  websiteUrl: null,
  facebook: null,
  twitter: null,
};

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    setSiteTitle(state, action: PayloadAction<string>) {
      state.siteTitle = action.payload;
    },
    setInstagram(state, action: PayloadAction<string>) {
      state.instagram = action.payload;
    },
    setWebsiteUrl(state, action: PayloadAction<string>) {
      state.websiteUrl = action.payload;
    },
    setFacebook(state, action: PayloadAction<string>) {
      state.facebook = action.payload;
    },
    setTwitter(state, action: PayloadAction<string>) {
      state.twitter = action.payload;
    },
  },
});

export const { setSiteTitle, setInstagram, setWebsiteUrl, setFacebook, setTwitter } = optionsSlice.actions;

export default optionsSlice.reducer;
