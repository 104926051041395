import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Homepage from './pages/Homepage/Landingsection';
import Header from './components/header';
import Footer from './components/Footer';
import BookingForm from './pages/Booking/BookingForm';
import BookingHeader from './components/BookingHeader';
import NotFoundPage from './pages/NotFoundPage';
import store from './stateManagers/store';
import SignIn from './pages/Booking/signIn';
import Faqs from './pages/Faqs';
import AppointmentComponent from './pages/Booking/Appoinment/AppointmentComponent';
import CalendarComponent from './pages/Booking/Appoinment/CalendarComponent';
import BookingDetailsTable from './pages/test';
import OurTeam from './pages/ourTeam';
import SalonServiceForm from './pages/SalonServiceForm';
import SinglePostPage from './pages/SinglePostPage';
import UserDashboard from './pages/UserDashoard/UserDashboard';
import Register from './pages/Booking/register';
import Aboutus from './pages/Aboutus';
import ForgotPassword from './pages/Booking/ForgotPassword';
import ScrollRestoration from './styles/Helpers/ScrollRestoration';
import { HelmetProvider } from 'react-helmet-async';
interface LocationState {
  fromParticularComponent?: boolean;
}

const AppRoutes: React.FC = () => {

  const location = useLocation();
  const path = location.search.replace('?/', '');
  const isBookingFormRoute = location.pathname === '/Booking';
  const issignUpRoute = location.pathname === '/register';
  const isslogInRoute = location.pathname === '/login';
  const isDashboardRoute = location.pathname === '/dashboard';
  const isUserDashboardRoute = location.pathname === '/dash';
  const isAppointmentComponentRoute = location.pathname === '/make-an-appointment';
  const isFromParticularComponent = (location.state as LocationState)?.fromParticularComponent;

  return (
    <HelmetProvider>
    <Provider store={store}>
      <div className="app-container">
        <div className="content">
          {!isBookingFormRoute && !issignUpRoute && !isslogInRoute && !isDashboardRoute && !isUserDashboardRoute &&  !isAppointmentComponentRoute && <Header />} {/* Render Header except on Booking, SignIn, and Dashboard routes */}
          {isBookingFormRoute && <BookingHeader />}
          {issignUpRoute && <BookingHeader />}
          {isslogInRoute && <BookingHeader />}
          <Routes>
            {/* <Route path="/" element={<Homepage />} /> */}
            <Route path="/login" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/Booking" element={<BookingForm />} />
            <Route path="/reset-password" element={<ForgotPassword />} />

            <Route
              path="/make-an-appointment"
              element={
                isFromParticularComponent ? (
                  <CalendarComponent />
                ) : (
                  <AppointmentComponent />
                )
              }
            />
            <Route path="*" element={<NotFoundPage />} />
            {/* <Route path="our-locations" element={<OurLocations />} /> */}
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/about-uss" element={<Aboutus />} />

            <Route path="dash" element={<UserDashboard />} />
            <Route path="test" element={<BookingDetailsTable />} />
            <Route path="our-team" element={<OurTeam />} />
            <Route path="create" element={<SalonServiceForm />} />

            <Route path="/post/:id" element={<SinglePostPage />} />
            <Route path="/:slug" element={<Homepage />} />
            <Route path="/" element={<Homepage />} />
            
          </Routes>
        </div>
        {!isDashboardRoute && !isUserDashboardRoute && !isslogInRoute && !isAppointmentComponentRoute && !issignUpRoute && <Footer />} {/* Render Footer except on Dashboard route */}
      </div>
    </Provider>
    </HelmetProvider>
  );
};

function App() {
  return (
    <Router basename={'/'}>
      <ScrollRestoration>
        <AppRoutes />
      </ScrollRestoration>
    </Router>
  );
}

export default App;
