import React, { useState } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography, Container, Paper, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../stateManagers/apiConfig';

const ForgotPassword: React.FC = () => {
const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSendOTP = async () => {
    // Validate email
    if (!email) {
      toast.error("Please enter your email");
      return;
    }

    try {
      setLoading(true);
      await axios.post(`${baseURL}/send-otp`, { email });
      setStep(2);
      toast.success("OTP sent successfully to your email!" , { autoClose: 1500 });
      
    } catch (error : any) {
      console.error(error);
      if(error.response.data.error === 'User not found'){
        toast.error("User not found");        
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    // Validate OTP and new password
    if (!otp || !newPassword) {
      toast.error("Please enter OTP and new password");
      return;
    }

    try {
      setLoading(true);
      await axios.post(`${baseURL}/reset-password`, { email, otp, newPassword });
      // Reset state and show success message
      toast.success("Password reset successful! You can now login!" , { autoClose: 2450 });
      setTimeout(() => {
        navigate('/login');
      }, 2500);
    } catch (error: any) {
      console.error(error);
      if(error.response.data.error === 'Invalid OTP'){
        toast.error("Invalid OTP");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs"
    sx={{
      height: "90vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <ToastContainer />

      <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        {step === 1 && (
          <Box component="form" sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#76a1b1",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#76a1b1", // Change the color here
                },
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#76a1b1",
                ":hover": {
                  bgcolor: "#6590a0",
                  color: "white",
                },  
              }}
              onClick={handleSendOTP}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Send OTP'}
            </Button>
          </Box>
        )}
        {step === 2 && (
          <Box component="form" sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="otp"
              label="OTP"
              name="otp"
              autoFocus
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#76a1b1",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#76a1b1", // Change the color here
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#76a1b1",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#76a1b1", // Change the color here
                },
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#76a1b1",
                ":hover": {
                  bgcolor: "#6590a0",
                  color: "white",
                },  
              }}
              onClick={handleResetPassword}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Reset Password'}
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
