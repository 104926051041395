import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, CardHeader, CardMedia, CardContent, Link } from '@mui/material';
import gift from '../images/gift.jpg'; // Import the image file
import GlobalHeading from '../../styles/components/GlobalHeading';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import Heading2 from '../../styles/components/Heading2';
import { Link as RouterLink } from 'react-router-dom';
import { BaseUrl } from '../../stateManagers/apiConfig';

interface BlogBlockProps {
  fullData: PageItem; // Declare fullData as PageItem type
}
// types.ts (or any suitable file)
export interface PageItem {
  heading: string;
  subheading: string;
  setcolumns:number;
}

const BlogBlock: React.FC<BlogBlockProps> = ({ fullData }) => {
  const heading = fullData.heading;
  const subheading = fullData.subheading;
  const setcolumns = fullData.setcolumns;
  
  const [postData, setPostData] = useState<any[]>([]);
  const [postId, setpostId] = useState<Number[]>([]);

  useEffect(() => {
    // Fetch data from API endpoint
    fetch(`${BaseUrl}/api/post/getpost`)
      .then(response => response.json())
      .then(data => {
        // console.log(data[0].id);
        const pId = data[0].id;
        setpostId(pId);
        const simplifiedData = data.map((post: any) => ({
          title: post.title,
          description: post.body.replace(/<\/?[^>]+(>|$)/g, '').split('\n')[0], // Remove HTML tags and get first line
        }));
        setPostData(simplifiedData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <section
      style={{
        position: 'relative',
        backgroundColor: 'white',
        padding: '75px 10% 95px 10%',
      }}
    >
      <Grid style={{ textAlign: 'center' }}>
        <GlobalHeading text={subheading} variant="h2" fontSize={{ xs: '12px', md: '12px' }} />
        <DynamicSpace gs={16} />
        <Heading2 text={heading} />
        <DynamicSpace gs={50} />
      </Grid>
      <Grid container>
        {postData.map((post, index) => (
          <Grid item xs={12} md={setcolumns} lg={setcolumns} key={index} maxWidth={'500px'}>
            <Card sx={{ margin: '0 auto', border: 'none', boxShadow: 'none'}}>
              <CardMedia
                component="img"
                height={(400 / 16) * 11} // Calculate the height for a 16:9 aspect ratio
                image={gift}
                alt={post.title}
                sx={{ borderBottom: '1px solid #ccc' }} // Add bottom border to separate media from header
              />
              <CardHeader
                title={post.title}
                titleTypographyProps={{
                  variant: 'h5',
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '18px',
                  letterSpacing: '.2em',
                  paddingTop: '10px',
                  paddingBottom: '0px',
                }}
                sx={{ paddingBottom: '5px' }} // Reduce padding bottom for header
              />
              <CardContent style={{ paddingBottom: '15px' }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    fontFamily: 'Futura, sans-serif',
                    fontSize: '14px',
                    lineHeight: '1.65',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3, // Show only first two lines
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {post.description}
                </Typography>
              <DynamicSpace gs={5} />    
              <RouterLink style={{fontSize:'13px', color:'black'}} to={`/post/${postId}`}>read more</RouterLink>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default BlogBlock;
