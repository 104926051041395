// servicesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface Service {
  price_ladies_oap: any;
  price_ladies: any;
  id: number;
  name: string;
  description: string;
  // Add other properties here
}

export interface ServicesState { // Export ServicesState interface
  selectedServices: Service[];
}

const initialState: ServicesState = {
  selectedServices: [],
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    selectService(state, action: PayloadAction<Service>) {
      state.selectedServices.push(action.payload);
    },
    deselectService(state, action: PayloadAction<number>) {
      state.selectedServices = state.selectedServices.filter(service => service.id !== action.payload);
    },
    clearSelectedServices(state) {
      state.selectedServices = [];
    },
  },
});

export const { selectService, deselectService, clearSelectedServices } = servicesSlice.actions;

export const selectSelectedServices = (state: RootState) => state.services.selectedServices;

export default servicesSlice.reducer;
